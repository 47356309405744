.loading-page {
    background: linear-gradient(135deg, #102032, #18283A);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loading-page .logo {
    width: calc(10% + 0.3vw);
    position: relative;
    margin: 0 auto 3vh auto;
    animation: none;
}

.loading-page #qualitatioLogo {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

@keyframes glowAnimationWeak {
    0% {
        filter: drop-shadow(0 0 2.5px #fff);
    }

    25% {
        filter: drop-shadow(0 0 3.75px #fff) drop-shadow(0 0 7.5px #fff);
    }

    50% {
        filter: drop-shadow(0 0 4.5px #fff) drop-shadow(0 0 9px #fff) drop-shadow(0 0 10px var(--m-3refprimaryprimary-90));
    }

    75% {
        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 10px #fff) drop-shadow(0 0 11.5px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 12.5px var(--m-3refprimaryprimary-90));
    }

    100% {
        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 10px #fff) drop-shadow(0 0 12.5px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 15px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 17.5px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 20px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 22.5px var(--m-3refprimaryprimary-90));
    }
}

@keyframes glowAnimationStrong {
    0% {
        filter: drop-shadow(0 0 5px #fff);
    }

    25% {
        filter: drop-shadow(0 0 7.5px #fff) drop-shadow(0 0 15px #fff);
    }

    50% {
        filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff) drop-shadow(0 0 20px var(--m-3refprimaryprimary-90));
    }

    75% {
        filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 25px #fff) drop-shadow(0 0 22.5px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 27.5px var(--m-3refprimaryprimary-90));
    }

    100% {
        filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff) drop-shadow(0 0 25px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 30px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 35px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 40px var(--m-3refprimaryprimary-90)) drop-shadow(0 0 45px var(--m-3refprimaryprimary-90));
    }
}

.loading-page .qualitatio-title {
    color: var(--m-3refprimaryprimary-90);
    font-family: "Nebula-Regular", Helvetica;
    font-size: calc(24px + 4vw);
    font-weight: 700;
    margin: 0 auto 2vh auto;
}

.loading-page .qualitatio-slogan {
    color: var(--m-3white);
    font-family: "Satoshi-Regular", Helvetica;
    font-size: calc(16px + 1vw);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
    margin: 0 auto 5vh auto;
}

.loading-page .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    background-color: white;
    z-index: 1;
}