.bottom-bar-white {
  align-items: center;
  background-color: #f7f8f8;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 10px 15px;
  position: relative;
  width: 100%;
}

.bottom-bar-white .frame-2 {
  position: relative;
  max-height: 56px;
  height: calc(20px + 1vh);
}

.bottom-bar-white .div-wrapper-bottom-bar {
  height: 12px;
  position: relative;
  text-align: right;
  width: 100%; /* Change this line to use 100% width */
}

.bottom-bar-white .text-wrapper-2 {
  color: var(--m-3black);
  font-family: "Roboto", Helvetica;
  font-size: calc(7px + min(0.5vh, 0.5vw));
  font-weight: 500;
  height: 12px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  white-space: nowrap;
  right: 2px; /* Add this line to position it on the right with a 10px margin */
}
