.register-page {
    width: 100%;
    height: 100%;
}

.register-page .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1;
}


.register-page .div-wrapper {
    align-self: stretch;
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 5vh;
}

.register-page .text-wrapper-2 {
    color: var(--m-3black);
    font-family: var(--m3-display-medium-bold-font-family);
    font-size: calc(24px + 1vw);
    font-style: var(--m3-display-medium-bold-font-style);
    font-weight: var(--m3-display-medium-bold-font-weight);
    letter-spacing: var(--m3-display-medium-bold-letter-spacing);
    line-height: var(--m3-display-medium-bold-line-height);
    position: relative;
    text-align: center;
    justify-content: center;
    margin-bottom: 2vh;
}

.register-page .profileImage {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.register-page .avatarContainer {
    width: 100%;
    height: 100%;
    max-width: 100px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    /* Ensure that the image doesn't spill out of the border */
}

.register-page .avatarContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Covers the whole container without stretching */
    object-position: center;
    /* Centers the image in its container */
}

.register-page .borderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1 / 1;
    position: relative;
    margin: 0 auto 3vh auto;
    background: linear-gradient(45deg, var(--m-3refprimaryprimary-70), #005b97);
    border-radius: 50%;
    padding: 4px;
    box-sizing: content-box;
}

.register-page .options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 1;
}


.register-page .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.register-page .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 2vh;
    margin-bottom: 3vh;
}

.register-page .div-3 {
    align-items: flex-start;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.register-page .text-field {
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--m-3refprimaryprimary-70);
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-height: 10vh;
}

.register-page .state-layer {
    align-items: center;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex: 0 0 auto;
    padding: 8px 16px 8px 16px;
    position: relative;
    width: 100%;
}

.register-page .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
}

.register-page .input-text {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
}

.register-page .input {
    align-self: stretch;
    background-color: transparent;
    border: 0;
    color: var(--m-3syslightoutline);
    flex: 1;
    flex-grow: 1;
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    margin-top: -1px;
    padding: 0;
    position: relative;
}

.register-page .input:focus {
    outline: none;
}

.register-page .label-text-wrapper {
    align-items: center;
    background-color: var(--m-3white);
    display: inline-flex;
    left: -4px;
    padding: 0px 4px;
    position: absolute;
    top: -16px;
}

.register-page .label-text-4 {
    color: var(--m-3refprimaryprimary-70);
    font-family: var(--m3-body-large-bold-font-family);
    font-size: var(--m3-body-large-bold-font-size);
    font-style: var(--m3-body-large-bold-font-style);
    font-weight: var(--m3-body-large-bold-font-weight);
    letter-spacing: var(--m3-body-large-bold-letter-spacing);
    line-height: var(--m3-body-large-bold-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.register-page .frame-4 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.register-page .passwort-merken {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-bottom: 1vh;
}

.register-page .text-wrapper-3 {
    color: var(--m-3black);
    font-family: var(--m3-title-small-font-family);
    font-size: var(--m3-title-small-font-size);
    font-style: var(--m3-title-small-font-style);
    font-weight: var(--m3-title-small-font-weight);
    letter-spacing: var(--m3-title-small-letter-spacing);
    line-height: var(--m3-title-small-line-height);
    position: relative;
    text-align: center;
}

.register-page .frame-5 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: auto;
    position: relative;
    margin: 0 auto 3vh auto;
}

.register-page .text-wrapper-4 {
    color: var(--m-3black);
    font-family: var(--m3-title-medium-font-family);
    font-size: var(--m3-title-medium-font-size);
    font-style: var(--m3-title-medium-font-style);
    font-weight: var(--m3-title-medium-font-weight);
    letter-spacing: var(--m3-title-medium-letter-spacing);
    line-height: var(--m3-title-medium-line-height);
    position: relative;
    text-align: right;
}

.register-page .switch-instance {
    position: relative;
}

.register-page .text-wrapper-5 {
    color: var(--m-3black);
    font-family: var(--m3-title-medium-font-family);
    font-size: var(--m3-title-medium-font-size);
    font-style: var(--m3-title-medium-font-style);
    font-weight: var(--m3-title-medium-font-weight);
    letter-spacing: var(--m3-title-medium-letter-spacing);
    line-height: var(--m3-title-medium-line-height);
    position: relative;
}

.register-page .frame-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.register-page .buttons {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 2vh;
    position: relative;
    width: 100%;
}

.register-page .camera-section {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* Change from column to row */
    align-items: center;
    /* Aligns Webcam and button vertically to center */
    justify-content: center;
    /* Centers the webcam */
    margin-bottom: 5vh;
}

.register-page .camera-section .camera-button-container {
    position: absolute;
    /* This will allow us to overlap the button over the webcam */
    left: calc(50% + 125px);
    /* 50% will bring it to the center, and we subtract half of the button's width to overlap it by 50% on the webcam */
    top: -25px;
}

.register-page .capture-button {
    width: 50px;
    /* Set a specific width */
    height: 50px;
    /* Make sure height equals width */
    display: flex;
    /* To center the icon */
    justify-content: center;
    align-items: center;
    background-color: var(--m-3refprimaryprimary-70);
    border: none;
    cursor: pointer;
    border-radius: 50%;
    transition: all .3s;
}

.register-page .button-instance {
    align-self: stretch !important;
    box-shadow: var(--m3-elevation-light-1) !important;
    left: unset !important;
    top: unset !important;
    width: 100% !important;
}

.register-page .design-component-instance-node {
    font-family: var(--m3-title-large-font-family) !important;
    font-size: var(--m3-title-large-font-size) !important;
    font-style: var(--m3-title-large-font-style) !important;
    font-weight: var(--m3-title-large-font-weight) !important;
    left: 150px !important;
    letter-spacing: var(--m3-title-large-letter-spacing) !important;
    line-height: var(--m3-title-large-line-height) !important;
}

.register-page .button-2 {
    align-self: stretch !important;
    background-color: var(--m-3white) !important;
    left: unset !important;
    margin-bottom: -8px !important;
    top: unset !important;
    width: 100% !important;
}

.register-page .schon-konto {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.register-page .sie-haben-schon-konto {
    color: transparent;
    flex: 1;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
    margin: 2vh auto 2vh auto;
    position: relative;
    text-align: center;
    white-space: wrap;
}

.register-page .span {
    color: #000000;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
}

.register-page .text-wrapper-6 {
    color: #1fbebc;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
}