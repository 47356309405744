.test-status-layout {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: 'test-status-header'
        'test-status-icon'
        'test-status-text'
        'test-status-buttons'
        'test-status-bottom-bar';
    gap: 4vh;
    margin-top: 3vh;
    max-height: calc(100vh - 60px - 70px);
}

.test-status-header {
    grid-area: test-status-header;
    display: flex;
    justify-content: center;
    font-family: var(--h4-ROB-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--h4-ROB-font-style);
    font-weight: var(--h4-ROB-font-weight);
    letter-spacing: var(--h4-ROB-letter-spacing);
}

.test-status-icon {
    grid-area: test-status-icon;
    display: flex;
    justify-content: center;
}

.test-status-image {
    width: calc(100px + 5vw);
    height: calc(100px + 5vh)
}

.test-status-text {
    grid-area: test-status-text;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: var(--m3-title-large-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--m3-title-large-font-style);
    font-weight: var(--m3-title-large-font-weight);
    letter-spacing: var(--m3-title-large-letter-spacing);
    line-height: var(--m3-title-large-line-height);
    gap: 1vh;
}

.test-status-text-how-many-tests,
.test-status-text-how-many-defects {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    gap: 1vw;
}

.test-status-text .test-status-number,
.test-status-text .test-status-tests {
    color: #1fbebd;
}

.test-status-buttons {
    grid-area: test-status-buttons;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
}

.test-status-bottom-bar {
    grid-area: test-status-bottom-bar;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
}