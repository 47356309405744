.welcome-page {
    background: linear-gradient(135deg, #102032, #18283A);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.welcome-page .particles {
    height: 100%;
    width: 50%;
    z-index: -1;
}

.welcome-page .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
    background-color: white;
    z-index: 1;
}

@media (max-width: 480px) { 
    .welcome-page .particles, #tsparticles {
        display: none;
    }

    .welcome-page .frame {
        width: 100% !important;
    }
}

.welcome-page .explanation {
    color: var(--m-3black);
    font-family: "Satoshi-Regular", Helvetica;
    font-size: calc(14px + 0.5vw);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 60%;
    margin: 0 auto 5vh auto;
}

.welcome-page .div-wrapper {
    align-self: stretch;
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 5vh;
}

.welcome-page .text-wrapper-2 {
    color: var(--m-3black);
    font-family: var(--m3-display-medium-bold-font-family);
    font-size: calc(24px + 1vw);
    font-style: var(--m3-display-medium-bold-font-style);
    font-weight: var(--m3-display-medium-bold-font-weight);
    letter-spacing: var(--m3-display-medium-bold-letter-spacing);
    line-height: var(--m3-display-medium-bold-line-height);
    position: relative;
    text-align: center;
    justify-content: center;
    margin-bottom: 5vh;
    width: 60%;
}

.welcome-page .logo {
    width: 12%;
    position: relative;
    margin-bottom: 5vh;
}

.welcome-page .frame-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    height: 166px;
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.welcome-page .buttons {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 2vh;
    position: relative;
    width: 100%;
}

.welcome-page .button-instance {
    align-self: stretch !important;
    box-shadow: var(--m3-elevation-light-1) !important;
    left: unset !important;
    top: unset !important;
    width: 100% !important;
}

.welcome-page .design-component-instance-node {
    font-family: var(--m3-title-large-font-family) !important;
    font-size: var(--m3-title-large-font-size) !important;
    font-style: var(--m3-title-large-font-style) !important;
    font-weight: var(--m3-title-large-font-weight) !important;
    left: 150px !important;
    letter-spacing: var(--m3-title-large-letter-spacing) !important;
    line-height: var(--m3-title-large-line-height) !important;
}

.welcome-page .button-2 {
    align-self: stretch !important;
    background-color: var(--m-3white) !important;
    left: unset !important;
    margin-bottom: -8px !important;
    top: unset !important;
    width: 100% !important;
}

.welcome-page .kein-konto {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.welcome-page .sie-haben-kein-konto {
    color: transparent;
    flex: 1;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
    margin: 2vh auto 2vh auto;
    position: relative;
    text-align: center;
    white-space: wrap;
}

.welcome-page .span {
    color: #000000;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
}

.welcome-page .text-wrapper-6 {
    color: #1fbebc;
    font-family: var(--m3-label-large-bold-font-family);
    font-size: var(--m3-label-large-bold-font-size);
    font-style: var(--m3-label-large-bold-font-style);
    font-weight: var(--m3-label-large-bold-font-weight);
    letter-spacing: var(--m3-label-large-bold-letter-spacing);
    line-height: var(--m3-label-large-bold-line-height);
}