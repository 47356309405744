.qualitatio-container {
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "nav pageContent"
    "footer footer";
}

.pageContent {
  grid-area: pageContent;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
}

:where(*) {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.navigationbaropen a, .navigationbarclosed a {
  text-decoration-color: var(--m-3refprimaryprimary-70);
  text-decoration-thickness: 1px;
}