.qualitatio-table-container {
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); */
    transition: all .3s !important;
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
}

/* .qualitatio-table-container:hover {
    transform: scale(1.01);
} */

/* Additional stylings can be added as needed to further customize the table appearance */