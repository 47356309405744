/* CSS for QualitatioTransferList */
.qualitatio-transfer-list {
    transition: all 0.3s !important;
    -webkit-transition: all 0.3s !important;
    border-radius: 4px;
}

.qualitatio-transfer-list .MuiOutlinedInput-root {
    border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-transfer-list .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-transfer-list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 106, 105, 1);
}

.qualitatio-transfer-list .MuiInputLabel-outlined.Mui-focused {
    color: rgba(0, 106, 105, 1);
}

.qualitatio-transfer-list .MuiInputLabel-shrink {
    color: var(--m-3refprimaryprimary-70);
}

/* Styling for the checkboxes and list items */
.qualitatio-transfer-list .MuiListItemIcon-root .MuiSvgIcon-root {
    fill: var(--m-3refprimaryprimary-70); /* Change this to your desired color */
}

/* Style for the most upper checkbox */
.qualitatio-transfer-list .MuiButtonBase-root .MuiSvgIcon-root{
    fill: var(--m-3refprimaryprimary-70); /* Change this to your desired color */
}

/* Style for the exchange buttons */
.qualitatio-transfer-list .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall {
    color: var(--m-3refprimaryprimary-70); /* Change this to your desired text color */
    border: 1px solid var(--m-3refprimaryprimary-70); /* Change this to your desired border color */
}

.qualitatio-transfer-list .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall:disabled {
    color: rgba(0, 0, 0, 0.26); /* Change this to your desired text color */
    border: 1px solid rgba(0, 0, 0, 0.12); /* Change this to your desired border color */
}

/* Hover style for the exchange buttons */
.qualitatio-transfer-list .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall:hover {
    color: rgba(0, 106, 105, 1); /* Change this to your desired hover text color */
    border: 1px solid rgba(0, 106, 105, 1); /* Change this to your desired hover border color */
}
