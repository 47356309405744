.card {
  border-radius: 8px;
  height: 100%;
}

.card.outline {
  border: 1px solid;
  border-color: var(--gray-200);
  width: 353px;
}

.card.filled {
  background-color: var(--gray-100);
  width: 352px;
}

.card.elevated {
  background-color: #ffffff;
  box-shadow: var(--base);
  width: 352px;
}
