.container {
    gap: 20px;
    align-items: center;
}

.leftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
}

.centeredDropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.previewContainer {
    position: relative;
    padding: 20px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.deleteButton {
    /* Removed position, top, right properties */
    background-color: #d32f2f;
    color: white;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    transition: background-color 0.3s ease;
}

.deleteButton:hover {
    background-color: #b71c1c;
}

.previewTitle {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    /* Remove any margins that might affect alignment */
}

.table {
    display: flex;
    flex-direction: column;
}

.tableHeader,
.tableRow {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.tableHeader {
    font-weight: bold;
    color: #555;
}

.tableBody {
    font-size: 0.9rem;
}

.tableCell {
    flex: 1;
    text-align: left;
    padding: 0 10px;
    color: #333;
}

.noMappings {
    text-align: center;
    padding: 10px;
    color: #999;
}