.defects-wrapper {
  display: grid;
  grid-template-rows: auto auto;
  /* grid-template-columns: auto minmax(0, 1fr); */
  grid-template-columns: auto minmax(0, 20vw);
  grid-template-areas: 'defects cards';
  gap: 1%;
}

.defects-wrapper .defects {
  grid-area: defects;
  padding: 11.66px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 50vw;
}

.defects-wrapper .defects-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.defects-wrapper .unlabeled-defects-checkbox {
  cursor: pointer;
}

.defects-wrapper .defects-table-wrapper {
  display: flex;
  align-items: top;
  margin-top: 15px;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.defects-wrapper .no-data {
  margin: 5vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}

.defects-wrapper .cards {
  grid-area: cards;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.test-card-navigation .basis-nav-buttons {
  cursor: pointer;
}

.cards .test-card-generation {
  max-width: 200px;
  gap: 10px;
  margin: 2% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-card-generation .test-card-generation-element {
  margin: 0 auto;
}
