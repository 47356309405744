.calculate-testing-layout {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "calculate-testing-header"
        "calculate-testing-spinner"
        "calculate-testing-text"
        "calculate-testing-bottom-bar";
    gap: 4vh;
    max-height: calc(100vh - 60px - 70px);
    margin-top: 4vh;
}

.calculate-testing-header {
    grid-area: calculate-testing-header;
    display: flex;
    justify-content: center;
    font-family: var(--h4-ROB-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--h4-ROB-font-style);
    font-weight: var(--h4-ROB-font-weight);
    letter-spacing: var(--h4-ROB-letter-spacing);
}

.calculate-testing-spinner {
    grid-area: calculate-testing-spinner;
    display: flex;
    justify-content: center;
}

.calculate-testing-text {
    grid-area: calculate-testing-text;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: var(--m3-title-large-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--m3-title-large-font-style);
    font-weight: var(--m3-title-large-font-weight);
    letter-spacing: var(--m3-title-large-letter-spacing);
    line-height: var(--m3-title-large-line-height);
    gap: 1vh;
}

.calculate-testing-text .calculate-testing-text-details {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    gap: 1vw;
}

.calculate-testing-text .product-id,
.calculate-testing-text .quality-station {
    color: #1fbebd;
}

.calculate-testing-bottom-bar {
    grid-area: calculate-testing-bottom-bar;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
}

