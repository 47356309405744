.icons-nio {
  background-color: #b00020;
  border: 0.6px solid;
  border-color: #0e0d13;
  border-radius: 12px;
  height: 24px;
  left: 162px;
  position: relative;
  top: 826px;
  width: 24px;
}

.icons-nio .close-cross-in {
  height: 12px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 12px;
}
