.building-blocks-wrapper {
  background-color: var(--m3syslightprimary-container);
  border-radius: 100px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.building-blocks-wrapper .initial {
  color: var(--m3syslighton-primary-container);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  left: 0;
  letter-spacing: 0.15px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
