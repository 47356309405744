.test-result-overview-layout-display .icons-nio-instance {
  background-color: #ba1a1a !important;
  border-color: #000000 !important;
  left: unset !important;
  top: unset !important;
}

.test-result-overview-layout-display .icons-io-instance {
  left: unset !important;
  top: unset !important;
}

.test-result-overview-layout-display .icons-io-fixed-instance {
  background-color: #3679f5 !important;
  border-color: #000000 !important;
  left: unset !important;
  top: unset !important;
}

.test-result-overview-layout-display {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'test-result-overview-header-bar'
    'test-result-overview-list'
    'test-result-overview-actions'
    'test-result-overview-bottom-bar';
  gap: 1vh;
  margin-top: 3vh;
  max-height: calc(100vh - 60px - 70px);
}

.test-result-overview-layout-hide {
  display: none;
}

.test-result-overview-header-bar {
  grid-area: test-result-overview-header-bar;
  display: flex;
  justify-content: center;
  width: 100%;
}

.test-result-overview-header-bar .test-result-overview-header-text {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-family: var(--h4-ROB-font-family);
  font-size: calc(15px + 0.6vw);
  font-style: var(--h4-ROB-font-style);
  font-weight: var(--h4-ROB-font-weight);
  letter-spacing: var(--h4-ROB-letter-spacing);
}

.test-result-overview-list {
  grid-area: test-result-overview-list;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
}

.test-result-overview-actions {
  grid-area: test-result-overview-actions;
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.test-result-overview-actions .test-result-overview-buttons {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
  flex-direction: column;
  gap: 3vh;
}

.test-result-overview-bottom-bar {
  grid-area: test-result-overview-bottom-bar;
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 10 !important;
}
