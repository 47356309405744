.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto;
}

.home .div-2 {
  background-color: #ffffff;
  height: 100%;
  position: relative;
  width: 100%;
}

.moduleCardWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr)); /* responsive columns with max 2 cards side by side */
  gap: 10% 5%; /* spacing between grid items */
  margin-left: 5%;
  max-width: 1000px;
  padding-bottom: 7%;
}

.home .page-header-instance {
  position: relative;
}

.home .text-wrapper-5 {
  color: var(--m-3black);
  font-family: var(--m3-headline-large-bold-font-family);
  font-size: var(--m3-headline-large-bold-font-size);
  font-style: var(--m3-headline-large-bold-font-style);
  font-weight: var(--m3-headline-large-bold-font-weight);
  letter-spacing: var(--m3-headline-large-bold-letter-spacing);
  line-height: var(--m3-headline-large-bold-line-height);
  position: relative;
  margin-left: 5%;
  margin-top: 5%;
}

.home .p {
  color: var(--m-3black);
  font-family: var(--m3-title-large-font-family);
  font-size: var(--m3-title-large-font-size);
  font-style: var(--m3-title-large-font-style);
  font-weight: var(--m3-title-large-font-weight);
  letter-spacing: var(--m3-title-large-letter-spacing);
  line-height: var(--m3-title-large-line-height);
  position: relative;
  margin-left: 5%;
  margin-top: 2%;
}

.home .modul-card-instance {
  background-color: var(--m-3white);
  position: relative;
  max-width: 450px;
}

.home .design-component-instance-node-3 {
  height: 30px;
  left: unset;
  position: relative;
  top: unset;
  width: 30px;
}

.home .modul-card-2 {
  background-color: var(--m-3white);
  position: relative;
  max-width: 450px;
  margin-left: 10%;
}

.home .modul-card-3 {
  background-color: var(--m-3white);
  position: relative;
  margin-left: 5%;
}

.home .modul-card-4 {
  background-color: var(--m-3white);
  position: relative;
  margin-left: 10%;
}
