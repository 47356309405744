/* setup-process-page.css */

.setup-process-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f4f4f4;
    /* Added a light background for contrast, you can remove it if not needed */
}

.setup-process-page-content {
    width: 33.33%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.setup-process-header {
    margin-bottom: 20px;
}

.setup-process-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 480px) {
    .setup-process-page-content {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }
}

.info-card {
    position: fixed;
    /* Makes the card stay at a fixed position */
    top: 50%;
    /* Places the top edge of the card at the vertical center */
    left: 50%;
    /* Places the left edge of the card at the horizontal center */
    transform: translate(-50%, -50%);
    /* Ensures the center of the card aligns with the center of the screen */
    width: 80%;
    /* Set to the desired width */
    max-width: 600px;
    /* Optional: Ensures the card doesn't get too wide on large screens */
    z-index: 1000;
    /* Optional: Ensures the card appears above other elements */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    /* Optional: Gives a shadow around the card for better visibility */
    border-radius: 8px;
    /* Optional: Rounds the edges of the card */
    padding: 20px;
}