.rules-wrapper {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 'rules cards';
  gap: 5%;
}

.rules-wrapper .rules {
  grid-area: rules;
  padding: 11.66px;
}

.rules-wrapper .cards {
  grid-area: cards;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.test-card-navigation .basis-nav-buttons {
  cursor: pointer;
}

.rules .rule-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background: var(--m-3-sys-light-surface-container-low, #f2f4f3);
  border: 0.58px solid;
  border-color: #c5c5c5e6;
  border-radius: 13.99px;
  box-shadow: 0px 6.63px 13.25px #1018280d;
  gap: 11.66px;
  height: 553.71px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 322.68px;
}

.rules .rule-header {
  align-items: center;
  background-color: transparent;
  border-radius: 13.99px;
  display: inline-flex;
  flex-direction: column;
  gap: 11.66px;
  left: 0;
  position: absolute;
  top: 0;
}

.rules .rule-body {
  display: flex;
  flex-direction: column;
  gap: 11.66px;
  margin-top: 33.82px;
  padding: 11.66px;
  width: 100%;
  overflow-y: auto;
}

.rules .card-2 {
  background-color: var(--m-3-sys-light-surface-container-highest, #e0e3e2);
  border-bottom-style: solid !important;
  border-bottom-width: 1.17px !important;
  border-color: #0e0d13 !important;
  border-radius: 13.99px 13.99px 0px 0px !important;
  height: 33.82px !important;
  position: relative !important;
  width: 320.68px !important;
}

.rules .text-wrapper-10 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  top: 6px;
  width: 100%;
  min-width: fit-content;
}

.rules .rule-test {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 1vh 1vw 0vh 1vw;
}

.rules .rule-test-name-wrapper {
  width: 70%;
}

.rules .rule-test-edit-wrapper,
.rules .rule-test-delete-wrapper {
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
}

.rules .add-rule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh 0 2vh 0;
}

.rules .dialog-rules {
  overflow-y: auto;
}

.rule-name-change-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Ensure items start from the left */
  align-items: center;
  gap: 1vh;
  padding: 2%;
}

.rule-logic-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  align-items: flex-start; /* Align items to the start to prevent centering */
  justify-content: flex-start; /* Start from the top */
  padding: 2%;
  overflow-y: auto; /* Allow scrolling when content overflows */
}

.rule-logic-wrapper .if-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1vh 0;
  height: 100%;
}

.rule-logic-wrapper .logic-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  gap: 1vh;
  flex-wrap: wrap;
  margin-bottom: 2vh;
  margin-top: 1vh;
}

.rule-logic-wrapper .logic-wrapper .qualitatio-input {
  min-width: 200px;
}

.test-card-manipulation .test-card-manipulation-element {
  margin: 0 auto;
}

/* Optional: Ensure buttons and dropdowns don't exceed container width */
.rule-logic-wrapper .logic-wrapper > .qualitatio-input,
.rule-logic-wrapper .logic-wrapper > .logical-operator {
  flex: 1 1 200px; /* Flexibly grow and shrink with a base width */
}
