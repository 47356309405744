.profile-page .div-wrapper {
  align-self: stretch;
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 3vh;
}

.profile-page .text-wrapper-2 {
  color: var(--m-3black);
  font-family: var(--m3-display-medium-bold-font-family);
  font-size: calc(14px + 1vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: var(--m3-display-medium-bold-line-height);
  position: relative;
  text-align: center;
  justify-content: left;
  margin-bottom: 2vh;
}

.profile-page .profileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.profile-page .avatarContainer {
  width: 100%;
  height: 100%;
  max-width: 100px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  /* Ensure that the image doesn't spill out of the border */
}

.profile-page .avatarContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Covers the whole container without stretching */
  object-position: center;
  /* Centers the image in its container */
}

.profile-page .borderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  margin: 0 auto 3vh auto;
  background: linear-gradient(45deg, var(--m-3refprimaryprimary-70), #005b97);
  border-radius: 50%;
  padding: 4px;
  box-sizing: content-box;
}

.profile-page .options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  /* Changed from relative to absolute */
  top: 88%;
  /* Positioned right below the avatar */
  left: 50%;
  /* Center it horizontally */
  transform: translateX(-50%);
  /* Adjust for exact centering */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1;
}

.profile-page .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile-page .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 2vh;
  margin-bottom: 3vh;
}

.profile-page .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  position: relative;
  width: 100%;
}

.profile-page .camera-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row; /* Change from column to row */
  align-items: center; /* Aligns Webcam and button vertically to center */
  justify-content: center; /* Centers the webcam */
  margin-bottom: 5vh;
}

.profile-page .camera-section .camera-button-container {
  position: absolute; /* This will allow us to overlap the button over the webcam */
  left: calc(
    50% + 125px
  ); /* 50% will bring it to the center, and we subtract half of the button's width to overlap it by 50% on the webcam */
  top: -25px;
}

.profile-page .capture-button {
  width: 50px; /* Set a specific width */
  height: 50px; /* Make sure height equals width */
  display: flex; /* To center the icon */
  justify-content: center;
  align-items: center;
  background-color: var(--m-3refprimaryprimary-70);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

.profile-page .buttons {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2vh;
  position: relative;
  width: 100%;
  margin-bottom: 5vh;
}

.camera-wrapper {
  z-index: 100;
}
