.test-card-stats-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Performance Section */
.test-card-stats-wrapper .test-card-performance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.test-card-stats-wrapper .performance-rate {
  font-size: 48px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-card-stats-wrapper .performance-graph {
  flex: 2;
  /* Add specific styles for your graph if needed */
}

.test-card-stats-wrapper .separator {
  height: 2px;
  background: #eee;
  margin: 20px 0;
}

/* Feedback Section */
.test-card-stats-wrapper .test-card-feedback {
  display: flex;
  flex-direction: column;
}

.test-card-stats-wrapper .feedback-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-card-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feedback-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.average-feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}

.average-feedback span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.test-card-stats-wrapper .average-feedback {
  font-size: 16px;
  margin-top: 6px;
}

.test-card-stats-wrapper .feedback-comments {
  margin: 20px 0px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.test-card-stats-wrapper .users-feedback {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  align-items: center;
}

.test-card-stats-wrapper .comment {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.test-card-stats-wrapper .comment-user {
  display: flex;
  align-items: center;
}

.test-card-stats-wrapper .user-name-time-wrapper {
  display: flex;
  flex-direction: column;
}

.test-card-stats-wrapper .user-image {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.test-card-stats-wrapper .user-name {
  font-size: 1rem;
  font-weight: normal;
  white-space: nowrap;
}

.test-card-stats-wrapper .comment-time {
  font-size: x-small;
  color: #aaa;
  text-align: left;
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.rating-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}

.label {
  font-weight: normal;
  margin-right: 20px;
  align-self: flex-start;
  text-align: left;
}

.rating-value {
  display: flex;
  justify-content: flex-end;
}

.rating-row .MuiTypography-root {
  font-weight: normal;
}

.feedback-text {
  font-size: 0.87rem;
  font-weight: 300;
  white-space: break-word;
  color: #807d7dc6;
}
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
