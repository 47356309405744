.qr-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  z-index: 20;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 25%;
  top: 5px;
  right: 5px;
  aspect-ratio: 1 / 1;
}

.qr-code-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
}

.custom-backdrop {
  opacity: 0.5 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
