/* Using the same styles from QualitatioInput.css */
.qualitatio-input {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border-radius: 4px;
}

.qualitatio-input .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70);
}

.compact-dropdown .qualitatio-input {
  height: 28px;
  padding: 0;
  font-size: 12px;
}

.compact-dropdown .MuiOutlinedInput-root {
  padding: 0;
  height: 28px;
}

.compact-dropdown .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}

.compact-dropdown .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.compact-dropdown .qualitatio-input {
  height: 28px;
  font-size: 12px;
}

.compact-dropdown .MuiOutlinedInput-root {
  padding: 0;
  height: 28px;
  line-height: 28px;
}

.compact-dropdown .MuiOutlinedInput-input {
  padding: 6px 14px;
  height: 28px;
  line-height: 28px;
}

.compact-dropdown .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
  font-size: small;
}

.compact-dropdown .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.65);
  font-size: medium;
}

.compact-dropdown .MuiInputBase-root,
.compact-dropdown .MuiInputBase-input {
  padding: 0 6px !important;
  font-size: small;
}

.compact-dropdown .MuiInputLabel-outlined {
  transform: translate(14px, 5px) scale(1);
}

.MuiAutocomplete-option {
  font-size: 16px; /* Adjust the font size of the dropdown options */
  padding: 8px 16px; /* Adjust padding for better readability */
}

.xs-dropdown .qualitatio-input {
  height: 36px; /* Adjust the height */
  padding: 0;
  font-size: 14px; /* Adjust the font size */
}

.xs-dropdown .MuiOutlinedInput-root {
  padding: 0;
  height: 36px; /* Adjust the height */
  line-height: 36px; /* Adjust the line-height */
}

.xs-dropdown .MuiOutlinedInput-input {
  padding: 4px 12px; /* Adjust the padding */
  height: 36px; /* Adjust the height */
  line-height: 36px; /* Adjust the line-height */
}

.xs-dropdown .MuiInputLabel-outlined {
  transform: translate(12px, 3px) scale(0.9); /* Adjust the transform values */
  font-size: 14px; /* Adjust the font size */
}

.xs-dropdown .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.85); /* Adjust the transform values */
  font-size: 14px; /* Adjust the font size */
  background-color: white;
  padding-right: 5px;
}

.xs-dropdown .MuiInputBase-root,
.xs-dropdown .MuiInputBase-input {
  padding: 0 6px !important; /* Adjust the padding */
  font-size: 14px; /* Adjust the font size */
}

/* Add any additional styles for the dropdown component if needed */
