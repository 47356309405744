/* Qualitatio Select */
.qualitatio-select {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border-radius: 4px;
}

.qualitatio-select .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-select .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1);
}

.qualitatio-select .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1);
}

.qualitatio-select .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70);
}

/* Compact Style for Qualitatio Select */
.compact-input .qualitatio-select {
  /* Adjust the height, padding, and font size for a more compact appearance */
  font-size: 12px;
  /* Smaller font size */
}

.compact-input .qualitatio-select .MuiOutlinedInput-root {
  height: 28px;
  /* Smaller height */
  padding: 0;
  /* Reduced padding */
}

.compact-input .qualitatio-select .MuiInputLabel-outlined {
  transform: translate(14px, 6px) scale(1);
  /* Adjust label position and scale */
  font-size: small;
  /* Smaller label font size */
}

.compact-input .qualitatio-select .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75);
  /* Adjust label position and scale for shrunk state */
}

/* XS Style for Qualitatio Select */
.xs-input .qualitatio-select {
  font-size: 10px;
  /* Even smaller font size */
}

.xs-input .qualitatio-select .MuiOutlinedInput-root {
  height: 24px;
  /* Smaller height for XS */
  padding: 0;
  /* Reduced padding for XS */
}

.xs-input .qualitatio-select .MuiInputLabel-outlined {
  transform: translate(12px, 4px) scale(0.9);
  /* Adjust label position and scale for XS */
  font-size: x-small;
  /* Smaller label font size for XS */
}

.xs-input .qualitatio-select .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.65);
  /* Adjust label position and scale for shrunk state in XS */
}
