.test-card-navigation .basis-nav-buttons {
    cursor: pointer;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2vh;
}

.test-card-manipulation .add-button {
    margin: 0 auto;
}

.test-card-manipulation .test-card-manipulation-element {
    margin: 0 auto;
}