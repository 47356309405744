.paperWithStripe::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #1fbebd; /* Cyan color, adjust as per your theme */
}

.paperWithStripe-highPriority::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #ba1a1a; /* Red color, adjust as per your theme */
}

.paperWithStripe-mediumPriority::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #ffe28a; /* Orange color, adjust as per your theme */
}
