.qualitatio-button {
    text-transform: none !important;
    transition: all .3s !important;
    -webkit-transition: all .3s !important;
    min-height: 40px !important;
}

.qualitatio-button:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}

.qualitatio-button .MuiButton-startIcon {
    position: absolute;
    left: 16px;
}

.MuiButton-endIcon {
    position: absolute;
    right: 16px;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.qualitatio-button.animate {
    animation: pulsate 2s;
    transition: all .5s !important;
    -webkit-transition: all .5s !important;
}

.start-icon-is-image img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.start-icon-rounded img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}