.monitor .monitor-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  flex-wrap: wrap;
  padding: 1vh 0;
}

.monitor .monitor-content-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  flex-wrap: wrap;
  padding: 1vh 0;
}

.monitor .upper-two-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5%;
  flex-wrap: wrap;
  padding: 1vh 0;
  width: 100%;
}

.monitor .lower-two-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5%;
  flex-wrap: wrap;
  padding: 1vh 0;
  width: 100%;
}

@media (max-width: 1000px) {
  .monitor .key-performance-indicators {
    width: 100% !important;
    min-width: 0% !important;
  }
}

@media (max-width: 1000px) {
  .monitor .defects-per-unit {
    width: 100% !important;
    min-width: 0% !important;
  }
}

@media (max-width: 1000px) {
  .monitor .defects-over-time {
    width: 100% !important;
    min-width: 0% !important;
  }
}

@media (max-width: 1000px) {
  .monitor .defects-by-category {
    width: 100% !important;
    min-width: 0% !important;
  }
}

.monitor .key-performance-indicators {
  width: 60%;
  min-width: 600px;
}

.monitor .defects-per-unit {
  width: 35%;
  min-width: 350px;
}

.monitor .defects-over-time {
  width: 60%;
  min-width: 600px;
}

.monitor .defects-by-category {
  width: 35%;
  min-width: 350px;
}

.monitor .overlap {
  position: relative;
}

.monitor .table-toolbar-header {
  background-color: var(--m3syslightsecondary-fixed) !important;
  border-color: var(--m-3refprimaryprimary-70) !important;
  position: relative;
  width: 100%;
  margin: 2vh 0 1vh 0;
  height: auto;
}

.monitor .table-toolbar-sub {
  background-color: var(--m3syslightsurface-container-highest) !important;
  border-color: var(--m-3refprimaryprimary-70) !important;
  position: relative;
}

.monitor .table-toolbar-2 {
  color: var(--m-3black) !important;
  font-family: var(--m3-title-medium-bold-font-family) !important;
  font-size: var(--m3-title-medium-bold-font-size) !important;
  font-style: var(--m3-title-medium-bold-font-style) !important;
  font-weight: var(--m3-title-medium-bold-font-weight) !important;
  letter-spacing: var(--m3-title-medium-bold-letter-spacing) !important;
  line-height: var(--m3-title-medium-bold-line-height) !important;
}

/* ********** KPI ************* */

.monitor .frame-16 {
  align-items: center;
  display: inline-flex;
  gap: 2vw;
  position: relative;
  flex-wrap: wrap;
  padding: 1vh;
}

.monitor .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.monitor .ellipse {
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 72px;
  height: 144px;
  position: relative;
  width: 144px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.monitor .ellipse:hover {
  background-color: var(--m-3refprimaryprimary-60);
  transform: scale(1.05);
}

.monitor .frame-18 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 23px;
  position: absolute;
  top: 46px;
  width: 99px;
}

.monitor .text-wrapper-6 {
  color: var(--m-3black);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.monitor .text-wrapper-7 {
  color: var(--m-3black);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  margin-left: -1.5px;
  margin-right: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.monitor .ellipse-2 {
  background-color: var(--m3syslightsurface-container-highest);
  border-radius: 60px;
  height: 120px;
  position: relative;
  width: 120px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.monitor .ellipse-2:hover {
  background-color: var(--m3syslightsurface-container-highest-highlight);
  transform: scale(1.05);
}

.monitor .frame-19 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 11px;
  position: absolute;
  top: 34px;
  width: 99px;
}

.monitor .text-wrapper-8 {
  color: var(--m3syslighton-surface-variant);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.monitor .text-wrapper-9 {
  color: var(--m3syslighton-surface-variant);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.monitor .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.monitor .ellipse-3 {
  background-color: var(--m3syslightsecondary-fixed);
  border-radius: 60px;
  height: 120px;
  position: relative;
  width: 120px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.monitor .ellipse-3:hover {
  background-color: var(--m3syslightsecondary-fixed-highlight);
  transform: scale(1.05);
}

.monitor .frame-21 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: calc(50% - 48px);
  position: absolute;
  top: calc(50% - 26px);
}

.monitor .text-wrapper-10 {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 97px;
}

.monitor .text-wrapper-11 {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.monitor .ellipse-4 {
  background-color: var(--m3sysdarkon-error-container);
  border-radius: 60px;
  height: 120px;
  position: relative;
  width: 120px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.monitor .ellipse-4:hover {
  background-color: var(--m3sysdarkon-error-container-highlight);
  transform: scale(1.05);
}

.monitor .text-wrapper-12 {
  color: var(--m-3syslighterror);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 97px;
}

.monitor .text-wrapper-13 {
  color: var(--m-3syslighterror);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

/* ********** Measure Board ************* */

/* ********** Chart diagramm ************* */

/* ********** Tab Table ************* */

.monitor .css-1pedu2u-MuiResponsiveChart-container {
  height: 60%;
  width: 80%;
  margin: 0 auto;
}

.monitor .css-1lbgfbl-MuiResponsiveChart-container {
  height: 80%;
  width: 100%;
  margin: 0 auto;
}

.monitor .no-data {
  margin: 5vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}
