.columnHeader {
  white-space: normal !important;
  line-height: 1.5 !important;
  height: auto !important;
}

.columnHeaderTitle {
  white-space: normal !important;
  text-overflow: clip !important;
  line-height: 1.5 !important;
}

.virtualScroller {
  overflow-x: auto !important;
}

.footerContainer {
  overflow-x: auto !important;
}