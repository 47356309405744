.swipeCardsLayout .io-frame {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 4.2px;
    position: relative;
}

.swipeCardsLayout .nio-frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4.8px;
    position: relative;
}

.swipeCardsLayout .card-recommandation-container {
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.05), 0px 1px 5px 1px rgba(0, 0, 0, 0.10);
}