.blur-pop-up {
  background-color: #0b2034;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.blur-pop-up .div-2 {
  background-color: var(--night-sky);
  border: 2.41px none;
  height: calc(100vh - 56px);
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.blur-pop-up .avatar-label-group-instance {
  position: absolute !important;
  top: 39px !important;
}

.blur-pop-up .design-component-instance-node {
  color: #f7f8f8 !important;
}

.blur-pop-up .avatar-label-group-2 {
  color: #9eaab0 !important;
}

.blur-pop-up .frame {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.21px;
  border-color: #f7f8f8;
  display: flex;
  gap: 9.69px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 9.69px;
  position: absolute;
  top: 207px;
  width: 179px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .frame:hover {
  transform: scale(0.9);
}

.blur-pop-up .img-2 {
  height: 20px;
  position: relative;
  width: 20px;
}

.blur-pop-up .text-wrapper-2 {
  color: var(--m-3refprimaryprimary-70);
  font-family: 'Roboto', Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.4px;
  margin-top: -1.21px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blur-pop-up .frame-2 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.21px;
  border-color: #f7f8f8;
  display: flex;
  gap: 9.69px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 9.69px;
  position: absolute;
  top: 252px;
  width: 179px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .frame-2:hover {
  transform: scale(0.9);
}

.blur-pop-up .overlap-group {
  height: 88px;
  position: relative;
  top: 297px;
  width: 179px;
  display: flex;
  align-items: center;
}

.blur-pop-up .frame-3 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.21px;
  border-color: #f7f8f8;
  display: flex;
  gap: 9.69px;
  left: 0;
  padding: 9.69px;
  position: absolute;
  top: 0;
  width: 179px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .frame-3:hover {
  transform: scale(0.9);
}

.blur-pop-up .frame-4 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.21px;
  border-color: #f7f8f8;
  display: flex;
  gap: 9.69px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 9.69px;
  position: absolute;
  top: 297px;
  width: 179px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .frame-4:hover {
  transform: scale(0.9);
}

.blur-pop-up .frame-5 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.21px;
  border-color: #f7f8f8;
  display: flex;
  gap: 9.69px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 9.69px;
  position: absolute;
  top: 386px;
  width: 179px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .frame-5:hover {
  transform: scale(0.9);
}

.blur-pop-up .group {
  height: 120px;
  left: 175px;
  position: absolute;
  top: 612px;
  width: 128px;
}

.blur-pop-up .text-wrapper-3 {
  color: var(--m-3refprimaryprimary-70);
  font-family: var(--h6-NEB-font-family);
  font-size: var(--h6-NEB-font-size);
  font-style: var(--h6-NEB-font-style);
  font-weight: var(--h6-NEB-font-weight);
  left: 0;
  letter-spacing: var(--h6-NEB-letter-spacing);
  line-height: var(--h6-NEB-line-height);
  position: absolute;
  top: 97px;
  white-space: nowrap;
  width: 123px;
}

.blur-pop-up .vector {
  height: 14px;
  left: 118px;
  position: absolute;
  top: -1px;
  width: 9px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .vector:hover {
  transform: scale(0.9);
}

/* Add new styles */
.blur-pop-up .logout-group {
  position: absolute;
  bottom: 30px;
  right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.blur-pop-up .logout-group:hover {
  transform: scale(0.9);
}

.blur-pop-up .logout-text {
  color: var(--m-3refprimaryprimary-70);
  font-family: var(--h6-NEB-font-family);
  font-size: var(--h6-NEB-font-size);
  font-style: var(--h6-NEB-font-style);
  font-weight: var(--h6-NEB-font-weight);
  letter-spacing: var(--h6-NEB-letter-spacing);
  line-height: var(--h6-NEB-line-height);
  white-space: nowrap;
  margin-right: 8px;
}

.blur-pop-up .logout-icon {
  height: 14px;
  width: 9px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}

.blur-pop-up .back-group {
  position: absolute;
  bottom: 30px;
  left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.blur-pop-up .back-group:hover {
  transform: scale(0.9);
}

.blur-pop-up .back-text {
  color: var(--m-3refprimaryprimary-70);
  font-family: var(--h6-NEB-font-family);
  font-size: var(--h6-NEB-font-size);
  font-style: var(--h6-NEB-font-style);
  font-weight: var(--h6-NEB-font-weight);
  letter-spacing: var(--h6-NEB-letter-spacing);
  line-height: var(--h6-NEB-line-height);
  white-space: nowrap;
  margin-left: 8px;
}

.blur-pop-up .back-icon {
  height: 14px;
  width: 9px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}
