.prod-config .step-0 {
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

/* Ensure Canvas fills its Grid item */
.prod-config .step-0 .Canvas {
  width: 100%;
  height: 100%;
}

.prod-config .configSettingsImage {
  width: 18px;
  height: 18px;
}

.prod-config .welcome-slogan {
  color: var(--m-3black);
  font-family: var(--m3-display-medium-bold-font-family);
  font-size: calc(14px + 1vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: calc(24px + 1vh);
  position: relative;
  text-align: center;
  justify-content: left;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.prod-config .configurator-description {
  margin: 3vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(16px + 1vh);
  text-align: center;
}

.prod-config .configurator-steps {
  margin: 3vh 0;
}

.prod-config .start-button {
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: 3vh;
}

.prod-config .start-button-wrapper {
  width: 30%;
  min-width: fit-content;
  max-width: 200px;
}

.prod-config .all-steps {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'backContainer cardContent nextContainer';
  grid-gap: 2%;
}

.prod-config .container-card {
  grid-area: cardContent;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 350px;
  margin: 15px auto;
  border-radius: 10px;
  box-shadow: var(--m3-elevation-light-1) !important;
  padding: 2%;
  min-height: 750px;
  height: 100%;
}

.prod-config .steps-overview {
  width: 100%;
  text-align: center;
}

.prod-config .add-quality-station-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.add-quality-station-modal .steps-overview-add-QS {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.add-quality-station-modal .mobile-stepper-container-add-QS {
  width: 30%;
  margin: 0 auto;
}

.add-quality-station-modal .add-quality-station-title {
  margin: 2vh 0;
  color: var(--m-3black);
  font-family: var(--m3-display-medium-font-family);
  font-size: calc(14px + 0.75vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: calc(12px + 1vh);
  position: relative;
  text-align: center;
}

.add-quality-station-modal .add-quality-station-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  gap: 2vh;
  margin: 3vh auto;
}

.add-quality-station-modal .error {
  margin-top: -1.5vh;
}

.add-quality-station-modal .add-quality-station-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 2vh;
  margin: 0vh auto 2vh auto;
}

.prod-config .choose-quality-station,
.prod-config .choose-test-logic,
.prod-config .add-test-card,
.prod-config .overview-about-test-cards {
  margin-top: 2vh;
  text-align: center;
  font-size: calc(12px + 0.75vw);
  font-weight: 600;
}

.prod-config .add-test-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
}

.prod-config .overview-about-test-cards {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  gap: 3vw;
}

.prod-config .quality-station-list,
.prod-config .test-logic-list {
  margin-top: 1vh;
}

.prod-config .quality-station,
.prod-config .test-logic {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 2vh 1vw;
}

.prod-config .quality-station-name-wrapper,
.prod-config .test-logic-name-wrapper {
  width: 90%;
}

.prod-config .quality-station-edit-wrapper,
.prod-config .test-logic-info-wrapper,
.prod-config .quality-station-delete-wrapper {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
}

.prod-config .button {
  box-shadow:
    0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.prod-config .button:hover {
  box-shadow:
    0px 1px 5px 2px rgba(0, 0, 0, 0.15),
    0px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.prod-config .add-quality-station {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh 0;
}

.prod-config .container-back {
  grid-area: backContainer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prod-config .buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center buttons horizontally */
  justify-content: flex-end;
  position: relative;
}

.prod-config .upper-icon {
  position: absolute;
  bottom: 20px;
}

.prod-config .container-next {
  grid-area: nextContainer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prod-config .highlighted-text {
  color: var(--m-3refprimaryprimary-70);
}

.cards .test-card-navigation {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  margin: 2% 0 0 0;
}

.cards .test-card-manipulation {
  width: 80%;
  max-width: 275px;
  margin: 2% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defect-favorite-header-container {
  border: 1px solid #008080; /* Input-like border color */
  border-radius: 10px; /* Rounded corners to match input fields */
  background-color: #ffffff; /* White background for the container */
  padding: 20px; /* Padding inside the container */
  margin-bottom: 20px; /* Space below the entire section */
  position: relative;
  margin-top: 10px;
}

.defect-favorite-header {
  font-size: 0.rem; /* Match font size */
  font-weight: 400; /* Light weight to match label style */
  color: var(--m-3refprimaryprimary-70); /* Use primary color to match other inputs */
  padding: 5px 5px; /* Reduced padding to make the text sit a bit higher */
  background-color: #ffffff; /* Matches the color of an input */
  position: absolute; /* Position for overlap with container */
  top: -15px; /* Moved higher to bring the text closer to the container border */
  left: 20px; /* Align left with the padding of the container */
}

.defect-favorite-content {
  padding-top: 25px; /* Adjusted space below the header */
}

.input-field-container {
  margin-bottom: 20px; /* Space between each input field */
  border-color: #008080; /* Matches the container border */
}
.custom-chips-input .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70); /* Matches default border color */
}

.custom-chips-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70); /* Consistent border color */
}

.custom-chips-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1); /* Border color when focused */
}

.custom-chips-input .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1); /* Label color when focused, matching other inputs */
}

.custom-chips-input .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70); /* Label color when shrunk */
}

.custom-chips-input .MuiChip-root {
  background-color: #e8f5e9; /* Light green background for chips */
  border: 1px solid var(--m-3refprimaryprimary-70); /* Custom border color */
  color:  var(--m-3refprimaryprimary-70); /* Dark green text color */
}

.custom-chips-input .MuiChip-root.Mui-focused {
  border-color: rgba(0, 106, 105, 1); /* Focused border color for chips */
}

.custom-chips-input .MuiChip-label {
  color: rgba(0, 106, 105, 1); /* Ensure the label color of the chips matches */
}


.suggestions-list {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

