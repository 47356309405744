.qualitatio-rating {
    color: var(--m-3refprimaryprimary-70);
    transition: all .3s !important;
}

/* Hover effect similar to button hover effect */
.qualitatio-rating:hover {
    transform: scale(1.03);
}

/* Star icon hover effect */
.qualitatio-rating .MuiRating-iconHover, 
.qualitatio-rating .MuiRating-iconHoverBase, 
.qualitatio-rating .MuiRating-iconFilled {
    color: var(--m-3refprimaryprimary-70) !important;
}

/* Star icon filled effect */
.qualitatio-rating .MuiRating-iconFilled {
    color: var(--m-3refprimaryprimary-70) !important;
}

/* Star icon empty effect */
.qualitatio-rating .MuiRating-iconEmpty {
    color: rgba(25, 28, 28, 0.5) !important; /* I'm using a lighter version of the disabled color for contrast */
}