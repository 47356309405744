.account-layout {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'account-welcome-bar'
        'account-content'
        'account-bottom-bar';
    gap: 4vh;
    max-height: calc(100vh - 60px - 70px);
    margin-top: 2vh;
    width: 100%;
}

.account-welcome-bar {
    grid-area: account-welcome-bar;
    display: flex;
    justify-content: center;
}

.account-welcome-bar .account-welcome-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    align-items: center;
}

.account-welcome-bar .account-welcome-text {
    font-family: var(--h4-ROB-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--h4-ROB-font-style);
    font-weight: var(--h4-ROB-font-weight);
    letter-spacing: var(--h4-ROB-letter-spacing);
}

.account-welcome-bar .options-list {
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: calc(35px + 4vh);
    /* Position the list right below the Avatar */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 10;
    left: calc(50% - 100px);
}

.account-welcome-bar .option {
    padding: 10px;
    border-bottom: 1px solid var(--m-3refprimaryprimary-70);
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.account-welcome-bar .option:hover {
    transform: scale(0.9);
}

.account-welcome-bar .option:last-child {
    border-bottom: none;
}

.account-content {
    grid-area: account-content;
    display: flex;
    justify-content: center;
    gap: 3vh;
}

.account-content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.account-content-wrapper .name-wrapper,
.account-content-wrapper .email-wrapper,
.account-content-wrapper .password-wrapper,
.account-content-wrapper .language-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom-style: solid;
    border-bottom-width: 1.21px;
    border-color: var(--m-3refprimaryprimary-70);
    padding: 2vh 0;
    gap: 2vw;
}

.account-content-wrapper .name-wrapper .label,
.account-content-wrapper .email-wrapper .label,
.account-content-wrapper .password-wrapper .label,
.account-content-wrapper .language-wrapper .label {
    word-break: normal;
}

.account-content-wrapper .account-button {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.account-bottom-bar {
    grid-area: account-bottom-bar;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
}