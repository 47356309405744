.navigationbarclosed {
  align-items: center;
  background-color: var(--customnightsky);
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 16px 8px;
  position: relative;
  width: 80px;
  grid-area: nav;
  z-index: 1;
}

.navigationbarclosed .home-link {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
  width: 40px;
}

.navigationbarclosed .link {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
  width: 40px;
}

.navigationbarclosed .link:hover {
  cursor: pointer;
  background-color: var(--customnightskypale);
}

.navigationbarclosed .link::before {
  content: attr(description);
  display: none;
  position: absolute;
  left: 110%;
  bottom: center;         /* This positions the tooltip above the link */
  background-color: var(--customnightskypale);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  white-space: nowrap;  /* This prevents the tooltip from wrapping onto multiple lines */
  font-size: 12px;     /* Adjust as needed */
  z-index: 1;          /* Ensures the tooltip appears above other content */
}

.navigationbarclosed .link:hover::before {
  display: block;
}

.navigationbarclosed .icons-einklappen-instance {
  left: unset !important;
  top: unset !important;
}

.navigationbarclosed .icons-einklappen-instance:hover {
  cursor: pointer;
}

.navigationbarclosed .mask-group {
  height: 24px;
  position: relative;
  width: 24px;
}

.navigationbarclosed .dropdown {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 40px;
}

.navigationbarclosed .links-container {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 40px;
}

.navigationbarclosed .design-component-instance-node-2 {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.navigationbarclosed .dropdown-2 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 40px;
}

.navigationbarclosed .design-component-instance-node-3 {
  left: unset !important;
  top: unset !important;
}