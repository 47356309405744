.register-requests {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.request-list {
    margin-top: 10px;
}

.card-fly-left {
    animation: flyLeft 0.6s forwards;
}

.card-fly-right {
    animation: flyRight 0.6s forwards;
}

@keyframes flyLeft {
    100% {
        transform: translateX(-150%);
        opacity: 0;
    }
}

@keyframes flyRight {
    100% {
        transform: translateX(150%);
        opacity: 0;
    }
}