/* Basic styling for the dialog content */
.qualitatio-dialog-content {
    font-size: 1rem;
    color: rgba(25, 28, 28, 1);
    /* default text color */
}

/* Adjustments for the title */
.qualitatio-dialog-title {
    font-weight: bold;
    color: var(--m-3refprimaryprimary-70);
    text-transform: capitalize;
}

/* Adjustments for the description text */
#qualitatio-dialog-description {
    margin-top: 10px;
    line-height: 1.5;
}

/* Dialog actions styling */
.qualitatio-dialog-actions {
    margin-top: 20px;
}

.MuiBackdrop-root, .MuiModal-backdrop {
    opacity: 0.5;  /* Adjust this value to your preference */
    background-color: rgba(0, 0, 0, 0.1);
}