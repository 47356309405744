.qualitatio-progress-bar .MuiStepper-root {
    /* background-color: var(--m-3refprimaryprimary-70); */
    border-radius: 8px;
}

.qualitatio-step-button {
    text-transform: none !important;
    transition: all .3s !important;
    color: var(--pure-white);
}

.qualitatio-step-button:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}

.qualitatio-progress-bar .MuiStepIcon-text {
    fill: white;
}

/* Add this to your CSS */
.step-container-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
/* Additional styling for completed steps, current step, etc. can be added here */