.container {
    /* center the content with 30% margin left and right */
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.listItem {
    padding-bottom: 8px;
    padding-top: 8px;
}

.listItemText {
    margin-right: 30px;
    /* Maintain right margin */
}

.addButton {
    width: 250px;
}

.addButtonMobile {
    width: 100%;
}

/* This is a listitem where i want the text to be centered */
.noTokens {
    text-align: center;
}