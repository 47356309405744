.layoutUserManagement .container {
  display: flex;
  justify-content: flex-end;
  /* Align items to the right */
  gap: 30px;
  /* Space between items */
  align-items: center;
}

@media (max-width: 768px) {
  .layoutUserManagement .container {
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-end;
    /* Align items to the right in column direction */
  }
}

.layoutUserManagement .multipleActions {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  gap: 30px;
  align-items: center;
}

.layoutUserManagement .buttonManage {
  /* Your CSS rules for buttonManage */
  width: 300px;
}

.layoutUserManagement .userMangerTable {
  margin-top: 3vh;
}

.layoutUserManagement .userManagerButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  align-items: center;
  height: 100%;
}

.layoutUserManagement .option {
  font-family: var(--m3-display-medium-font-family);
}
