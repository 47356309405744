.actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 15px;
}

.input-fields-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    overflow-y: scroll;
    height: 100%;
}