.aiOptimizer .aiOptimizerAllSteps {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "backContainer cardContent nextContainer";
    grid-gap: 2%;
}

.aiOptimizer .container-card {
    grid-area: cardContent;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    min-width: 350px;
    margin: 15px auto;
    border-radius: 10px;
    box-shadow: var(--m3-elevation-light-1) !important;
    padding: 2%;
    min-height: 750px;
}

.aiOptimizer .steps-overview {
    width: 100%;
    text-align: center;
}

.aiOptimizer .error-message {
    color: red;
    font-size: calc(7.5px + 0.25vw);
    font-weight: var(--m3-headline-small-bold-font-weight);
    margin-top: 2vh;
}

.aiOptimizer .select-streams,
.aiOptimizer .select-data-source,
.aiOptimizer .name-the-model,
.aiOptimizer .select-data-set,
.aiOptimizer .select-fields,
.aiOptimizer .map-feature-fields,
.aiOptimizer .define-priorization,
.aiOptimizer .define-retraining-and-caching,
.aiOptimizer .check-model-settings,
.aiOptimizer .evaluate-the-model-performance {
    margin-top: 2vh;
    text-align: center;
    font-size: calc(12px + 0.75vw);
    font-weight: 600;
}

.aiOptimizer .select-streams {
    margin-bottom: 2vh;
}

.aiOptimizer .model-name-input,
.aiOptimizer .csv-or-live,
.aiOptimizer .data-set-input,
.aiOptimizer .feature-field-mapping,
.aiOptimizer .built-in-algorithm,
.aiOptimizer .define-all-settings {
    margin-top: 2vh;
}

.aiOptimizer .csv-or-live,
.aiOptimizer .data-set-input,
.aiOptimizer .list-of-all-fields,
.aiOptimizer .feature-field-mapping {
    display: flex;
    justify-content: center;
    gap: 5vw;
}

.aiOptimizer .step-2 {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "select-data-source"
        "csv-or-live"
        "date-picker";
    grid-gap: 2%;
}

.aiOptimizer .step-2 .csv-or-live {
    grid-area: csv-or-live;
    display: flex;
    flex-direction: row;
    gap: 3vh;
    margin-top: 2vh;
}

.aiOptimizer .step-2 .date-picker {
    grid-area: date-picker;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    margin-top: 2vh;
    align-items: center;
}

.aiOptimizer .step-2 .date-picker .select-date,
.aiOptimizer .step-3-live .select-prediction-fields .select-defect-classes-as-predictions,
.aiOptimizer .step-3-live .select-data-source-as-features .select-data-sources-as-features-text,
.aiOptimizer .step-3-live-summary .select-prediction-fields .select-defect-classes-as-predictions,
.aiOptimizer .step-3-live-summary .select-data-source-as-features .select-data-sources-as-features-text,
.aiOptimizer .step-4-live .list-of-all-fields-and-names .stream-name,
.aiOptimizer .step-4-live-summary .list-of-all-fields-and-names .stream-name,
.aiOptimizer .step-6 .natural-ordering .natural-ordering-text,
.aiOptimizer .step-6-summary .natural-ordering .natural-ordering-text,
.aiOptimizer .step-6 .priority-of-kpi .priority-of-kpi-text,
.aiOptimizer .step-6-summary .priority-of-kpi .priority-of-kpi-text,
.aiOptimizer .step-6 .keep-learning .keep-learning-text,
.aiOptimizer .step-6-summary .keep-learning .keep-learning-text {
    font-weight: var(--m3-headline-small-bold-font-weight);
}

.aiOptimizer .step-4,
.aiOptimizer .step-4-summary {
    display: grid;
    grid-template-rows: 1fr auto 1fr 3fr;
    grid-template-areas: "select-fields"
        "list-legend"
        "divider-container"
        "list-of-all-fields"
        "feature-and-prediction-fields";
    grid-gap: 2%;
}

.aiOptimizer .step-4-live,
.aiOptimizer .step-4-live-summary {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-areas: "select-fields"
        "list-legend"
        "divider-container"
        "list-of-all-fields-and-names";
    grid-gap: 2%;
}

.aiOptimizer .step-4 .select-fields,
.aiOptimizer .step-4-summary .select-fields,
.aiOptimizer .step-4-live .select-fields,
.aiOptimizer .step-4-live-summary .select-fields {
    grid-area: select-fields;
}

.aiOptimizer .step-4 .list-legend,
.aiOptimizer .step-4-summary .list-legend,
.aiOptimizer .step-4-live .list-legend,
.aiOptimizer .step-4-live-summary .list-legend {
    grid-area: list-legend;
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: center;
    margin-top: 3vh;
}

.aiOptimizer .step-4 .divider-container,
.aiOptimizer .step-4-summary .divider-container,
.aiOptimizer .step-4-live .divider-container,
.aiOptimizer .step-4-live-summary .divider-container {
    grid-area: divider-container;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1px;
}

.aiOptimizer .step-4 .divider-container .MuiDivider-root,
.aiOptimizer .step-4-summary .divider-container .MuiDivider-root,
.aiOptimizer .step-4-live .divider-container .MuiDivider-root,
.aiOptimizer .step-4-live-summary .divider-container .MuiDivider-root {
    border-top: 1px solid var(--m-3refprimaryprimary-70);
    width: 100%;
}


.aiOptimizer .step-4 .list-of-all-fields,
.aiOptimizer .step-4-summary .list-of-all-fields {
    grid-area: list-of-all-fields;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    max-width: 100%;
    height: fit-content;
}

.aiOptimizer .step-4 .list-of-all-fields>*,
.aiOptimizer .step-4-summary .list-of-all-fields>* {
    flex: 0 0 calc(16.66% - 10px);
}

.aiOptimizer .step-4-live .list-of-all-fields-and-names,
.aiOptimizer .step-4-live-summary .list-of-all-fields-and-names {
    grid-area: list-of-all-fields-and-names;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.aiOptimizer .step-4-live .list-of-all-fields,
.aiOptimizer .step-4-live-summary .list-of-all-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
}

.aiOptimizer .step-4-live .list-of-all-fields>*,
.aiOptimizer .step-4-live-summary .list-of-all-fields>* {
    flex: 0 0 calc(16.66% - 10px);
}

.aiOptimizer .step-4 .feature-and-prediction-fields,
.aiOptimizer .step-4-summary .feature-and-prediction-fields {
    grid-area: feature-and-prediction-fields;
}

.aiOptimizer .step-5 .map-feature-fields,
.aiOptimizer .step-5-summary .map-feature-fields {
    width: 100%;
}

.aiOptimizer .step-5 .feature-field-mapping,
.aiOptimizer .step-5-summary .feature-field-mapping {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2vh;
}

.aiOptimizer .step-4-step-5-summary {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "step-3-live-summary"
        "step-4-summary"
        "step-5-summary";
    grid-gap: 2%;
}

.aiOptimizer .step-4-step-5-summary .step-3-live-summary {
    grid-area: step-3-live-summary;
}

.aiOptimizer .step-4-step-5-summary .step-4-summary {
    grid-area: step-4-summary;
}

.aiOptimizer .step-4-step-5-summary .step-5-summary {
    grid-area: step-5-summary;
    margin-bottom: 2vh;
}

/* Optional: Style the grid items */
.aiOptimizer .step-5 .grid-item,
.aiOptimizer .step-5-summary .grid-item,
.aiOptimizer .step-6 .grid-item,
.aiOptimizer .step-6-summary .grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.aiOptimizer .step-5 .option,
.aiOptimizer .step-5-summary .option,
.aiOptimizer .step-8 .is-active {
    font-family: var(--m3-display-medium-font-family);
}

.aiOptimizer .step-6,
.aiOptimizer .step-6-summary {
    height: 100%;
    gap: 2vh;
}

.aiOptimizer .step-6 .natural-ordering,
.aiOptimizer .step-6 .priority-of-kpi,
.aiOptimizer .step-6-summary .natural-ordering,
.aiOptimizer .step-6-summary .priority-of-kpi,
.aiOptimizer .step-6 .keep-learning,
.aiOptimizer .step-6-summary .keep-learning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    margin-top: 4vh;
}

.aiOptimizer .step-8 {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "check-model-settings"
        "model-settings";
    grid-gap: 2vh;
}

.aiOptimizer .step-8 .check-model-settings {
    grid-area: check-model-settings;
}

.aiOptimizer .step-8 .model-settings {
    grid-area: model-settings;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.aiOptimizer .step-8 .is-active {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    gap: 2vw;
}

.aiOptimizer .summary-date-time-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vh;
    justify-content: left;
    width: 100%;
}

.aiOptimizer .button {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.aiOptimizer .button:hover {
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.15), 0px 1px 5px 1px rgba(0, 0, 0, 0.30);
}

.aiOptimizer .container-back {
    grid-area: backContainer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aiOptimizer .container-next {
    grid-area: nextContainer;
    display: flex;
    justify-content: center;
    align-items: center;
}