/* Basic styling for the dialog content */
.create-rule-form-dialog-content {
  font-size: 1rem;
  color: rgba(25, 28, 28, 1);
  /* default text color */
}

/* Adjustments for the title */
.create-rule-form-dialog-title {
  font-weight: bold;
  color: var(--m-3refprimaryprimary-70);
  text-transform: capitalize;
}

/* Adjustments for the description text */
#create-rule-form-dialog-description {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 11px;
}

/* Dialog actions styling */
.create-rule-form-dialog-actions {
  margin-top: 20px;
}

.MuiBackdrop-root,
.MuiModal-backdrop {
  opacity: 0.5 !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.create-rule-form-dialog-button-on-the-left {
  width: 50%;
}

.create-rule-form-dialog-other-buttons-full {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}

.create-rule-form-dialog-other-buttons-half {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 50%;
}

@keyframes highlight-pulse-animation {
  0% {
    transform: scale(1.5);
    color: #ff0000;
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
    color: #ff0000;
  }
}

.create-rule-modal-highlight {
  animation: highlight-pulse-animation 0.8s ease-out;
}
