.node circle {
  fill: #f3f3ff;
  stroke: #2593b8;
  stroke-width: 1.5px;
}

.node text {
  font-size: 13px;
  background-color: white;
  fill: black;
  text-shadow: 0 1px 4px white;
}

.node {
  cursor: pointer;
}

.node.searchIncluded text {
  font-weight: bold;
  font-size: 14px;
}

.node.searchExcluded text {
  opacity: 0.6;
}

path.link {
  fill: none;
  stroke: #2593b8;
  stroke-width: 1.5px;
}
