.main-layout {
    background: linear-gradient(135deg, #102032, #18283A);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "particles frame";
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.main-layout .particles {
    height: 100%;
    width: 100%;
    grid-area: particles;
    z-index: -1;
}

.main-layout .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    grid-area: frame;
    background-color: white;
    z-index: 1;
}

@media (max-width: 480px) {

    .main-layout.particles,
    #tsparticles {
        display: none;
    }

    .main-layout {
        grid-template-columns: 1fr;
        grid-template-areas: "frame";
    }
}