.modul-card-pr {
  align-items: flex-end;
  background-color: var(--m3syslightsurface-container-low);
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px 30px;
  position: relative;
}

.buttonWrapper {
  width: 30%;
}

.modul-card-pr .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.modul-card-pr .rectangle {
  background-color: var(--m-3syslightprimary);
  border-radius: 56.55px;
  height: 5.09px;
  position: relative;
  width: 96px;
}

.modul-card-pr .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.modul-card-pr .pr-fplanung-3 {
  color: var(--m-3syslightprimary);
  font-family: var(--m3-headline-small-font-family);
  font-size: var(--m3-headline-small-font-size);
  font-style: var(--m3-headline-small-font-style);
  font-weight: var(--m3-headline-small-font-weight);
  letter-spacing: var(--m3-headline-small-letter-spacing);
  line-height: var(--m3-headline-small-line-height);
  margin-top: -1px;
  position: relative;
  width: 264px;
}

.modul-card-pr .supporting-text {
  color: var(--m3syslighton-surface-variant);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  position: relative;
  width: 241px;
}

.modul-card-pr .button-instance {
  align-items: flex-end !important;
  left: unset !important;
  top: unset !important;
}
