.eventsWrapper {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 'events cards';
  gap: 5%;
}

.events {
  grid-area: events;
  padding: 11.66px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: calc(35vw);
}

.eventsHeaderWrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  align-items: center;
}

.unlabeledEventsCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dateTimePickers {
  display: flex;
  gap: 10px;
  align-items: center;
}

.eventsTableWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 520px;
  margin-top: 30px;
}

.noData {
  margin: 5vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}

.cards {
  grid-area: cards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 350px;
}

.basisNavButtons {
  cursor: pointer;
  margin: 0 10px;
}

.testCardNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.testCardNavigationElement {
  display: flex;
  align-items: center;
}

.testCardControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.navigationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.manipulationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
}

.testCardControlElement {
  margin: 0 10px;
}

.basisNavButtons {
  cursor: pointer;
  margin: 0 5px;
}
