.outer-wrapper {
    position: relative;
}

.card-wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 11.66px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.card-wrapper .card-light-wrapper {
    align-items: flex-start;
    display: inline-flex;
    gap: 11.66px;
    padding: 11.66px;
    position: relative;
}

.card-wrapper .card-light {
    align-items: flex-start;
    background-color: #ffffff;
    border: 0.58px solid;
    border-color: #c5c5c5e6;
    border-radius: 13.99px;
    box-shadow: 0px 6.63px 13.25px #1018280d;
    display: flex;
    gap: 11.66px;
    height: 553.71px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    position: relative;
    width: 322.68px;
}

.card-wrapper .card-frame {
    background-color: var(--white);
    border-radius: 13.99px;
    height: 555.2px;
    margin-bottom: -1.5px;
    position: relative;
    width: 320.68px;
}

.card-wrapper .overlap-group-3 {
    border-radius: 13.99px;
    box-shadow: 0px 4.66px 4.66px #00000040;
    height: 555px;
    position: relative;
    width: 100%;
}

.card-wrapper .card-button-line {
    align-items: center;
    border-radius: 13.99px 13.99px 0px 0px;
    display: inline-flex;
    flex-direction: column;
    gap: 11.66px;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 535px;
}

.card-wrapper .card-instance {
    background-color: var(--m-3refprimaryprimary-70) !important;
    border-color: #0e0d13 !important;
    border-radius: 0px 0px 13.99px 13.99px !important;
    border-top-style: solid !important;
    border-top-width: 1.17px !important;
    height: 32px !important;
    position: relative !important;
    width: 320px !important;
}

.card-wrapper .text-wrapper-3 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: calc(5px + 0.5vw);
    font-weight: 400;
    height: 23px;
    left: calc(50% - 154px);
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 6px;
    width: 309px;
}

.card-wrapper .overlap {
    height: 439px;
    left: 10px;
    position: absolute;
    top: 43px;
    width: 300px;
    max-height: 100vh;
}

.card-wrapper .frame-4 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 0;
    position: absolute;
    top: 0;
}

.card-wrapper .highres {
    height: 199px;
    object-fit: cover;
    position: relative;
    width: 298px;
}

.card-wrapper .frame-5 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.card-wrapper .div-3 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 6.63px 13.25px #1018280d;
    display: flex;
    gap: 11.66px;
    height: 40px;
    padding: 2.33px 11.66px;
    position: relative;
    width: 300px;
}

.card-wrapper .design-component-instance-node {
    height: 23.32px !important;
    left: unset !important;
    position: relative !important;
    top: unset !important;
    width: 23.32px !important;
}

.card-wrapper .text-wrapper-4 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 700;
    height: 36.15px;
    letter-spacing: 0;
    line-height: 17.5px;
    margin-top: -1.57px;
    position: relative;
    width: 239.05px;
    display: flex;
    align-items: center;
}

.card-wrapper .text-wrapper-5 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 400;
    height: 34.98px;
    letter-spacing: 0;
    line-height: 17.5px;
    margin-top: -0.99px;
    position: relative;
    width: 239.05px;
    display: flex;
    align-items: center;
}

.card-wrapper .text-wrapper-6 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 400;
    height: 37.32px;
    letter-spacing: 0;
    line-height: 17.5px;
    margin-top: -2.16px;
    position: relative;
    width: 239.05px;
    display: flex;
    align-items: center;
}

.card-wrapper .p {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 400;
    height: 36.15px;
    letter-spacing: 0;
    line-height: 15.2px;
    margin-top: -1.57px;
    position: relative;
    width: 239.05px;
    overflow: auto;
    display: flex;
    align-items: center;
}

.card-wrapper .ai-outline-star-wrapper {
    background-color: #0b2034;
    border-radius: 50%;
    height: 35px;
    left: 259px;
    position: absolute;
    top: 157px;
    width: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-wrapper .ai-outline-star {
    position: relative;
}

.card-wrapper .pic-points {
    align-items: flex-end;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5.83px 5.83px;
    justify-content: center;
    left: 127px;
    position: absolute;
    top: 180px;
}

.card-wrapper .ellipse {
    background-color: var(--nightsky);
    border-radius: 5.83px;
    height: 11.66px;
    position: relative;
    width: 11.66px;
}

.card-wrapper .ellipse-2 {
    background-color: #f5f7fa;
    border-radius: 5.83px;
    height: 11.66px;
    position: relative;
    width: 11.66px;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .ellipse-2:hover {
    transform: scale(0.9);
}

.card-wrapper .mask-group {
    height: 24px;
    left: 275px;
    position: absolute;
    top: 92px;
    width: 23px;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .mask-group:hover {
    transform: scale(0.9);
}

.card-wrapper .mask-group-2 {
    height: 24px;
    left: 1px;
    position: absolute;
    top: 92px;
    width: 23px;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .mask-group-2:hover {
    transform: scale(0.9);
}

.card-wrapper .badge-base-instance-nightsky {
    background-color: var(--nightsky) !important;
    border-radius: 18.66px !important;
    display: flex !important;
    left: 189px !important;
    padding: 2.33px 11.66px !important;
    position: absolute !important;
    top: 8px !important;
    width: 105px !important;
}

.card-wrapper .badge-base-instance-blue {
    background-color: var(--m-3refprimaryprimary-70) !important;
    border-radius: 18.66px !important;
    display: flex !important;
    left: 189px !important;
    padding: 2.33px 11.66px !important;
    position: absolute !important;
    top: 8px !important;
    width: 105px !important;
}

.card-wrapper .badge-base-2 {
    font-family: var(--h7-ROB-font-family) !important;
    font-size: var(--h7-ROB-font-size) !important;
    font-style: var(--h7-ROB-font-style) !important;
    font-weight: var(--h7-ROB-font-weight) !important;
    letter-spacing: var(--h7-ROB-letter-spacing) !important;
    line-height: var(--h7-ROB-line-height) !important;
    margin-top: -1.17px !important;
}

.card-wrapper .frame-6 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 7px;
    left: 55px;
    position: absolute;
    top: 492px;
}

.card-wrapper .i-o-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .i-o-wrapper:hover {
    transform: scale(0.9);
}

.card-wrapper .group-wrapper {
    height: 60px;
    position: relative;
    width: 60px;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .group-wrapper:hover {
    transform: scale(0.9);
}

.card-wrapper .mask-group-wrapper {
    background-color: #1fbebd;
    border-radius: 30px;
    box-shadow: 0px 6.63px 13.25px #1018280d;
    height: 60px;
    position: relative;
}

.card-wrapper .mask-group-3 {
    height: 30px;
    left: 14px;
    position: absolute;
    top: 16px;
    width: 30px;
}

.card-wrapper .div-wrapper-text {
    height: 16px;
    position: relative;
    width: 40px;
}

.card-wrapper .text-wrapper-7 {
    color: var(--m-3refprimaryprimary-70);
    font-family: var(--m3-label-medium-prominent-font-family);
    font-size: var(--m3-label-medium-prominent-font-size);
    font-style: var(--m3-label-medium-prominent-font-style);
    font-weight: var(--m3-label-medium-prominent-font-weight);
    height: 16px;
    left: 0;
    letter-spacing: var(--m3-label-medium-prominent-letter-spacing);
    line-height: var(--m3-label-medium-prominent-line-height);
    position: absolute;
    text-align: center;
    top: -1px;
    width: 40px;
}

.card-wrapper .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 135px;
    position: absolute;
    top: 498px;
}

.card-wrapper .frame-wrapper {
    box-shadow: 0px 6.63px 13.25px #1018280d;
    height: 48px;
    position: relative;
    width: 48px;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.card-wrapper .frame-wrapper:hover {
    transform: scale(0.9);
}

.card-wrapper .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    gap: 10px;
    position: relative;
}

.card-wrapper .ellipse-3 {
    background-color: var(--variable-collection-pure-white);
    border: 0.58px solid;
    border-color: var(--variable-collection-light-gray);
    border-radius: 24px;
    height: 48px;
    position: relative;
    width: 48px;
}

.card-wrapper .group-3 {
    background-size: 100% 100%;
    height: 24px;
    left: 12px;
    position: absolute;
    top: 12px;
    width: 23px;
}

.card-wrapper .frame-9 {
    height: 32px;
    position: relative;
    width: 49px;
}

.card-wrapper .text-wrapper-8 {
    color: var(--m3syslightinverse-surface);
    font-family: var(--m3-label-medium-prominent-font-family);
    font-size: var(--m3-label-medium-prominent-font-size);
    font-style: var(--m3-label-medium-prominent-font-style);
    font-weight: var(--m3-label-medium-prominent-font-weight);
    height: 32px;
    left: 0;
    letter-spacing: var(--m3-label-medium-prominent-letter-spacing);
    line-height: var(--m3-label-medium-prominent-line-height);
    position: absolute;
    text-align: center;
    top: -4px;
    width: fit-content;
}

.card-wrapper .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    left: 205px;
    position: absolute;
    top: 492px;
}

.card-wrapper .img-wrapper {
    background-color: #b00020;
    border-radius: 30px;
    box-shadow: 0px 6.63px 13.25px #1018280f, 0px 6.63px 19.88px #1018281a;
    height: 60px;
    width: 60px;
    position: relative;
}

.card-wrapper .mask-group-4 {
    height: 30px;
    left: 16px;
    position: absolute;
    top: 16px;
    width: 30px;
}

.card-wrapper .frame-11 {
    height: 15px;
    position: relative;
    width: 61px;
}

.card-wrapper .text-wrapper-9 {
    color: var(--m-3syslighterror);
    font-family: var(--m3-label-medium-prominent-font-family);
    font-size: var(--m3-label-medium-prominent-font-size);
    font-style: var(--m3-label-medium-prominent-font-style);
    font-weight: var(--m3-label-medium-prominent-font-weight);
    height: 16px;
    left: 0;
    letter-spacing: var(--m3-label-medium-prominent-letter-spacing);
    line-height: var(--m3-label-medium-prominent-line-height);
    position: absolute;
    text-align: center;
    top: -1px;
    width: 61px;
}

.card-wrapper .cardHeader {
    align-items: center;
    background-color: transparent;
    border-radius: 13.99px;
    display: inline-flex;
    flex-direction: column;
    gap: 11.66px;
    left: 0;
    position: absolute;
    top: 0;
}

.card-wrapper .card-2 {
    background-color: var(--m-3refprimaryprimary-70) !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1.17px !important;
    border-color: #0e0d13 !important;
    border-radius: 13.99px 13.99px 0px 0px !important;
    height: 33.82px !important;
    position: relative !important;
    width: 320.68px !important;
}

.card-wrapper .delete-button {
    position: absolute;
    top: 0;
    left: 0;
}

.card-wrapper .text-wrapper-10 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 400;
    height: 23px;
    left: 188px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 6px;
    width: 122px;
    min-width: fit-content;
}

.card-wrapper .text-wrapper-11 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 400;
    height: 23px;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 6px;
    width: 99px;
}

.card-wrapper .text-wrapper-12 {
    color: #0e0d13;
    font-family: "Roboto", Helvetica;
    font-size: 16.3px;
    font-weight: 500;
    height: 23px;
    left: calc(50% - 31px);
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 6px;
    width: 61px;
}

.card-wrapper .img {
    height: 10px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 10px;
}

.card-wrapper .swipe {
    position: absolute;
    border-radius: 5px;
    max-height: inherit;
}


.card-wrapper .tinderCardWrapper {
    transition: all .5s;
    -webkit-transition: all .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 1vh;
    height: 80vh;
}

.card-wrapper .input-title {
    font-weight: 900;
    font-family: var(--m3-label-small-font-family);
}

.card-wrapper .document-frame-wrapper {
    background-color: var(--m3syslightsecondary-fixed);
    border-radius: 8px;
    height: 200px;
    position: relative;
    width: 300px;
}

.card-wrapper .document-frame-5 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    left: 98px;
    position: relative;
    top: 68px;
}

.card-wrapper .document-frame-6 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
    z-index: 2;
}

.card-wrapper .document-text-wrapper-4 {
    color: var(--m-3refprimaryprimary-70);
    font-family: var(--m3-label-large-prominent-font-family);
    font-size: var(--m3-label-large-prominent-font-size);
    font-style: var(--m3-label-large-prominent-font-style);
    font-weight: var(--m3-label-large-prominent-font-weight);
    letter-spacing: var(--m3-label-large-prominent-letter-spacing);
    line-height: var(--m3-label-large-prominent-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.camera-wrapper {
  z-index: 100;
  position: fixed;
  top: 56px; /* Height of the header */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.react-html5-camera-photo-fullscreen > video {
    height: calc(100vh - 56px);
  }