.prod-config-efficient-testing .step-0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 65%;
  margin: 0 auto;
}

.prod-config-efficient-testing .configSettingsImage {
  width: 18px;
  height: 18px;
}

.prod-config-efficient-testing .welcome-slogan {
  color: var(--m-3black);
  font-family: var(--m3-display-medium-bold-font-family);
  font-size: calc(14px + 1vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: calc(24px + 1vh);
  position: relative;
  text-align: center;
  justify-content: left;
  margin-top: 15vh;
  margin-bottom: 2vh;
}

.prod-config-efficient-testing .configurator-description {
  margin: 3vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(16px + 1vh);
  text-align: center;
}

.prod-config-efficient-testing .configurator-steps {
  margin: 3vh 0;
}

.prod-config-efficient-testing .start-button {
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: 3vh;
}

.prod-config-efficient-testing .start-button-wrapper {
  width: 30%;
  min-width: fit-content;
  max-width: 200px;
}

.prod-config-efficient-testing .all-steps {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'backContainer cardContent nextContainer';
  grid-gap: 2%;
}

.prod-config-efficient-testing .container-card {
  grid-area: cardContent;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 350px;
  margin: 15px auto;
  border-radius: 10px;
  box-shadow: var(--m3-elevation-light-1) !important;
  padding: 2%;
  min-height: 750px;
}

.prod-config-efficient-testing .steps-overview {
  width: 100%;
  text-align: center;
}

.prod-config-efficient-testing .add-quality-station-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.add-quality-station-modal .steps-overview-add-QS {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.add-quality-station-modal .mobile-stepper-container-add-QS {
  width: 30%;
  margin: 0 auto;
}

.add-quality-station-modal .add-quality-station-title {
  margin: 2vh 0;
  color: var(--m-3black);
  font-family: var(--m3-display-medium-font-family);
  font-size: calc(14px + 0.75vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: calc(12px + 1vh);
  position: relative;
  text-align: center;
}

.add-quality-station-modal .add-quality-station-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  gap: 2vh;
  margin: 3vh auto;
}

.add-quality-station-modal .error {
  margin-top: -1.5vh;
}

.add-quality-station-modal .add-quality-station-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 2vh;
  margin: 0vh auto 2vh auto;
}

.prod-config-efficient-testing .choose-quality-station,
.prod-config-efficient-testing .choose-model,
.prod-config-efficient-testing .add-test-card,
.prod-config-efficient-testing .overview-about-test-cards {
  margin-top: 2vh;
  text-align: center;
  font-size: calc(12px + 0.75vw);
  font-weight: 600;
}

.prod-config-efficient-testing .sensitivity-slider {
  margin: 3vh auto;
  width: 50%;
  text-align: left;
  font-weight: 500;
}

.prod-config-efficient-testing .sensitivity-explanation {
  margin: 5vh auto;
  width: 80%;
  text-align: center;
  max-width: 700px;
}

.prod-config-efficient-testing .quality-station-list,
.prod-config-efficient-testing .test-logic-list {
  margin-top: 1vh;
}

.prod-config-efficient-testing .quality-station,
.prod-config-efficient-testing .test-logic {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 2vh 1vw;
}

.prod-config-efficient-testing .quality-station-name-wrapper,
.prod-config-efficient-testing .test-logic-name-wrapper {
  width: 90%;
}

.prod-config-efficient-testing .quality-station-edit-wrapper,
.prod-config-efficient-testing .test-logic-info-wrapper,
.prod-config-efficient-testing .quality-station-delete-wrapper {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
}

.prod-config-efficient-testing .button {
  box-shadow:
    0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.prod-config-efficient-testing .button:hover {
  box-shadow:
    0px 1px 5px 2px rgba(0, 0, 0, 0.15),
    0px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.prod-config-efficient-testing .add-quality-station {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh 0;
}

.prod-config-efficient-testing .container-back {
  grid-area: backContainer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prod-config-efficient-testing .buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center buttons horizontally */
  justify-content: flex-end;
  position: relative;
}

.prod-config-efficient-testing .upper-icon {
  position: absolute;
  bottom: 20px;
}

.prod-config-efficient-testing .container-next {
  grid-area: nextContainer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prod-config-efficient-testing .highlighted-text {
  color: var(--m-3refprimaryprimary-70);
}

.cards .test-card-navigation {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  margin: 2% 0 0 0;
}

.cards .test-card-manipulation {
  width: 80%;
  max-width: 275px;
  margin: 2% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
