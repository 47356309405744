/* Using the same styles from QualitatioInput.css */
.qualitatio-input {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border-radius: 4px;
}

.qualitatio-input .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70);
}

.qualitatio-chips-dropdown-compact .MuiOutlinedInput-root {
  padding: 0; /* Reduce padding that may increase height */
  min-height: 28px; /* Compact height similar to your other compact inputs */
}

.qualitatio-chips-dropdown-compact .MuiOutlinedInput-input {
  padding: 4px 14px; /* Slightly reduced padding to align text and manage overall height */
  height: 28px; /* Ensure the text field itself does not expand unnecessarily */
  line-height: 28px;
}

.qualitatio-chips-dropdown-compact .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1); /* Adjust label position for compact size */
  font-size: small; /* Maintain compact font size */
}

.qualitatio-chips-dropdown-compact .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.65); /* Adjust shrink transform for compact size */
  font-size: medium; /* Slightly larger font when label is shrunken */
}

.qualitatio-chips-dropdown-compact .MuiInputBase-root,
.qualitatio-chips-dropdown-compact .MuiInputBase-input {
  padding: 0;
  font-size: small; /* Smaller font size for compact appearance */
}

.qualitatio-chips-dropdown-compact .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 14px !important; /* Remove default vertical padding, adjust horizontal as needed */
}

.qualitatio-chips-dropdown-compact .MuiChip-root {
  height: 22px; /* Maintain smaller chip height */
  font-size: 12px; /* Smaller font size for chips */
  margin: 2px; /* Small margins around chips */
}

.xs-chip-dropdown .qualitatio-input {
  height: 24px; /* Adjust the height */
  padding: 0;
  font-size: 10px; /* Adjust the font size */
}

.xs-chip-dropdown .MuiOutlinedInput-root {
  padding: 0;
  height: 24px; /* Adjust the height */
  line-height: 24px; /* Adjust the line-height */
}

.xs-chip-dropdown .MuiOutlinedInput-input {
  padding: 4px 12px; /* Adjust the padding */
  height: 24px; /* Adjust the height */
  line-height: 24px; /* Adjust the line-height */
}

.xs-chip-dropdown .MuiInputLabel-outlined {
  transform: translate(12px, 6px) scale(0.9); /* Adjust the transform values */
  font-size: x-small; /* Adjust the font size */
}

.xs-chip-dropdown .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.65); /* Adjust the transform values */
  font-size: x-small; /* Adjust the font size */
}

.xs-chip-dropdown .MuiInputBase-root,
.xs-chip-dropdown .MuiInputBase-input {
  padding: 0 4px !important; /* Adjust the padding */
  font-size: x-small; /* Adjust the font size */
}

/* Add any additional styles for the dropdown component if needed */
