.qualitatio-mobile-stepper {
  max-width: 100%;
  flex-grow: 1;
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 8px;
}

.qualitatio-mobile-stepper button {
  text-transform: none !important;
  color: var(--pure-white);
}

.qualitatio-mobile-stepper button:hover {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}

/* Add more styling as needed */