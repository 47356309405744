.overview-wrapper {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "overview cards";
    gap: 5%;
}

.overview-wrapper .overview {
    grid-area: overview;
    padding: 11.66px;
}

.overview-wrapper .cards-overview {
    grid-area: cards;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.overview-body .choose-quality-station, .overview-body .choose-test-logic, .cards-overview-body .quality-station-model, .cards-overview-body .quality-station-sensitivity {
    font-size: calc(12px + 0.25vw);
    margin-top: 2vh;
    text-align: left;
}

.overview-body .choose-quality-station-wrapper {
    margin-bottom: 3vh;
}

.test-card-navigation .basis-nav-buttons {
    cursor: pointer;
}

.overview .overview-wrapper, .cards-overview .cards-overview-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    background: var(--m-3-sys-light-surface-container-low, #F2F4F3);
    border: 0.58px solid;
    border-color: #c5c5c5e6;
    border-radius: 13.99px;
    box-shadow: 0px 6.63px 13.25px #1018280d;
    gap: 11.66px;
    height: 553.71px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    position: relative;
    width: 322.68px;
}

.cards-overview .sensitivity-slider {
    width: 90%;
}

.overview .overview-body, .cards-overview .cards-overview-body {
    display: flex;
    flex-direction: column;
    gap: 11.66px;
    margin-top: 33.82px;
    padding: 11.66px;
    width: 100%;
    overflow-y: auto;
    font-weight: 600;
}

.cards-overview .test-card-manipulation .test-card-manipulation-element {
    margin: 0 10px;
}

.cards-overview .no-test-cards {
    margin: 5vh 0;
    color: var(--m-3black);
    font-family: "Roboto", Helvetica;
    font-size: calc(12px + 0.25vw);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
}

.cards-overview .test-card-navigation {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    margin: 2% 0 0 0;
}

.cards-overview .test-card-manipulation {
    width: 80%;
    max-width: 275px;
    margin: 2% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}