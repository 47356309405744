.icons-io {
  background-color: #1fbebc;
  border: 0.57px solid;
  border-color: #000000;
  border-radius: 12px;
  height: 24px;
  left: 131px;
  position: relative;
  top: 826px;
  width: 24px;
}

.icons-io .check-mark-in-a {
  height: 12px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 12px;
}
