.table-toolbar {
  align-items: center;
  background-color: var(--m-3white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--m-3syslightprimary);
  display: flex;
  gap: 8px;
  padding: 13px 16px;
  position: relative;
}

.table-toolbar .table-name {
  color: var(--m-3syslightprimary);
  flex: 1;
  font-family: var(--m3-title-medium-font-family);
  font-size: var(--m3-title-medium-font-size);
  font-style: var(--m3-title-medium-font-style);
  font-weight: var(--m3-title-medium-font-weight);
  letter-spacing: var(--m3-title-medium-letter-spacing);
  line-height: var(--m3-title-medium-line-height);
  position: relative;
}

.table-toolbar .class {
  flex: 0 0 auto !important;
  left: unset !important;
  margin-bottom: -5px !important;
  margin-top: -5px !important;
  top: unset !important;
}

.table-toolbar .class-2 {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.table-toolbar .class-3 {
  flex: 0 0 auto !important;
  left: unset !important;
  margin-bottom: -1px !important;
  margin-top: -1px !important;
  top: unset !important;
}

.table-toolbar .title-frame-iconset-instance {
  background-image: url(https://c.animaapp.com/sgYo7tvE/img/icon-24@2x.png) !important;
  left: unset !important;
  top: unset !important;
}

.table-toolbar .instance-node {
  background-image: url(https://c.animaapp.com/sgYo7tvE/img/icon-22@2x.png) !important;
  left: unset !important;
  top: unset !important;
}

.table-toolbar .title-frame-iconset-2 {
  background-image: url(https://c.animaapp.com/sgYo7tvE/img/icon-23@2x.png) !important;
  left: unset !important;
  top: unset !important;
}

.table-toolbar .title-frame-iconset-3 {
  background-color: var(--m3syslightsurface-dim) !important;
}

.table-toolbar.large {
  height: 56px;
  top: 16px;
}

.table-toolbar.medium {
  height: 48px;
  top: 88px;
}

.table-toolbar.small {
  height: 40px;
  top: 152px;
}

.table-toolbar.medium .table-name {
  margin-top: -2px;
}

.table-toolbar.small .table-name {
  margin-bottom: -4px;
  margin-top: -6px;
}
