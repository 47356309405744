.support-page .headline {
  color: var(--m-3black);
  font-family: var(--m3-display-medium-bold-font-family);
  font-size: calc(14px + 1vw);
  font-style: var(--m3-display-medium-bold-font-style);
  font-weight: var(--m3-display-medium-bold-font-weight);
  letter-spacing: var(--m3-display-medium-bold-letter-spacing);
  line-height: var(--m3-display-medium-bold-line-height);
  position: relative;
  text-align: left;
  justify-content: left;
  margin-bottom: 2vh;
}

.support-page .contact-card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  flex-wrap: wrap;
}

.support-page .support-information {
  margin: 5vh 0;
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}
