.container {
  /* center the content with 30% margin left and right */
  padding-left: 20%;
  padding-right: 20%;
}
.listItem {
  padding-bottom: 8px;
  padding-top: 8px;
}

.listItemText {
  margin-right: 30px; /* Maintain right margin */
}

.addButton {
  width: 250px;
}

.addButtonMobile {
  width: 100%;
}
