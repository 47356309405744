.module-card {
    align-items: flex-start;
    border-radius: 12px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    box-shadow: var(--m3-elevation-light-1);
    transition: box-shadow 0.2s ease-in-out;
    -webkit-transition: box-shadow 0.2s ease-in-out;
}

.module-card:hover {
    box-shadow: var(--m3-elevation-light-4);
}

.module-card-pr {
    align-items: flex-end;
    background-color: var(--m3syslightsurface-container-low);
    border-radius: 12px;
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 15px;
    position: relative;
}

.module-card .class-2 {
    background-color: var(--m-3white) !important;
    flex: 0 0 auto !important;
    left: unset !important;
    top: unset !important;
}

.contact-card .card-sm {
    align-items: center;
    background-color: var(--m3syslighton-primary);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 140px;
    justify-content: center;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding: 24px;
    position: relative;
    width: 400px;
}

.contact-card .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
}

.contact-card .div-3 {
    height: 80px;
    position: relative;
    width: 80px;
}

.contact-card .group {
    height: 80px;
}

.contact-card .ellipse-wrapper {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
}

.contact-card .ellipse {
    height: 86px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 86px;
}

.contact-card .mask-group {
    height: 42px;
    left: 19px;
    position: absolute;
    top: 19px;
    width: 42px;
}

.contact-card .avatarContainer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden;
}

.contact-card .avatarContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.contact-card .borderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1 / 1;
    position: relative;
    background: linear-gradient(45deg, var(--m-3refprimaryprimary-70), #005b97);
    border-radius: 50%;
    padding: 2px;
    box-sizing: content-box;
}

.contact-card .frame-7 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 226px;
}

.contact-card .text-wrapper-5 {
    color: #000000;
    font-family: var(--m3-headline-small-bold-font-family);
    font-size: calc(12px + 0.5vw);
    font-style: var(--m3-headline-small-bold-font-style);
    font-weight: var(--m3-headline-small-bold-font-weight);
    letter-spacing: var(--m3-headline-small-bold-letter-spacing);
    line-height: var(--m3-headline-small-bold-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.contact-card .text-wrapper-6 {
    color: #000000;
    font-family: var(--m3-title-medium-font-family);
    font-size: var(--m3-title-medium-font-size);
    font-style: var(--m3-title-medium-font-style);
    font-weight: var(--m3-title-medium-font-weight);
    letter-spacing: var(--m3-title-medium-letter-spacing);
    line-height: var(--m3-title-medium-line-height);
    position: relative;
    width: fit-content;
}

.contact-card .text-wrapper-6 a {
    color: #000000;
}