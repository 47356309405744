.header {
  align-items: center;
  background-color: var(--night-sky);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--pure-white);
  display: flex;
  grid-area: header;
  height: 56px;
  justify-content: space-between;
  padding: 0px 20px;
  position: sticky;
  width: 100%;
  z-index: 2;
}

.header .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  position: relative;
}

.header .frame:hover {
  cursor: pointer;
}

.header .frame-2 {
  background-image: url('../../../public/images/qualitatio.svg');
  background-size: 100% 100%;
  height: 31px;
  position: relative;
  width: 26.75px;
}

.header .div-wrapper {
  height: 28px;
  position: relative;
}

.header .text-wrapper-2 {
  color: var(--m-3refprimaryprimary-90);
  font-family: 'Nebula-Regular', Helvetica;
  font-size: calc(16px + 0.4vw);
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

@media (max-width: 480px) {
  .header .text-wrapper-2 {
    display: none;
  }
}

.header .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  justify-content: space-around;
  padding: 8px;
  position: relative;
  max-width: 500px;
}

.header .frame-3 {
  align-items: center;
  display: flex;
  gap: 10%;
  justify-content: flex-end;
  position: relative;
}

.header .avatar {
  margin-top: 10%;
}

.header .avatarContainer {
  width: 100%;
  height: 100%;
  max-width: 45px;
  min-width: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  /* Ensure that the image doesn't spill out of the border */
}

.header .avatarContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Covers the whole container without stretching */
  object-position: center;
  /* Centers the image in its container */
  position: relative;
}

.header .borderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  background: linear-gradient(45deg, var(--m-3refprimaryprimary-70), #005b97);
  border-radius: 50%;
  padding: 2px;
  box-sizing: content-box;
}

.header .frame-4 {
  flex: 0 auto;
  position: relative;
  max-height: 46px;
  min-height: 30px;
  margin: 5px 0px;
}

.header .user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin-right: 5px;
  opacity: 0;
  transform: scale(0.8);
  transition:
    opacity 1.5s ease,
    transform 1.5s ease;
}

.header .user-avatar-visible {
  opacity: 1;
  transform: scale(1);
  z-index: 5;
  position: relative;
}

.user-avatar-visible::after {
  content: attr(description);
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 110%;
  background-color: var(--customnightskypale);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 100;
}

.user-avatar-visible:hover::after {
  display: block;
  z-index: 100;
}

.avatarContainer {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
