.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.loadingSpinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingSpinner {
    margin-bottom: 20px;
    /* Adds space between the spinner and the text */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}