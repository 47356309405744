.cardWrapperLayout {
  background-color: #ffffff;
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  grid-template-areas: 'swipeCards';
}

.cardWrapperGridLayout {
  display: 'flex';
  gap: '2vh';
  height: '100vh';
}
