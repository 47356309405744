.scan-layout {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'scan-upper-bar'
    'scan-product'
    'scan-bottom-bar';
  gap: 4vh;
  max-height: calc(100vh - 60px - 70px);
  margin-top: 2vh;
}

.scan-upper-bar {
  grid-area: scan-upper-bar;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: var(--h4-ROB-font-family);
  font-size: calc(15px + 0.6vw);
  font-style: var(--h4-ROB-font-style);
  font-weight: var(--h4-ROB-font-weight);
  letter-spacing: var(--h4-ROB-letter-spacing);
}

.scanner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-upper-bar .scan-upper-bar-content {
  display: flex;
  justify-content: space-around;
  width: 80%;
  align-items: center;
}

.scan-upper-bar .scan-upper-bar-text {
  word-break: break-word;
  display: flex;
  max-width: calc(50px + 15vw);
}

.scan-upper-bar .image {
  position: relative;
  height: 48px;
  width: 48px;
}

.scan-product {
  grid-area: scan-product;
  display: flex;
  justify-content: center;
}

.scan-product .scan-product-content {
  display: flex;
  justify-content: center;
  width: 80%;
  gap: 8vh;
  flex-direction: column;
}

.scan-product .scan-product-input {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 3vh;
  flex-direction: column;
}

.scan-product .scan-product-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 3vh;
  flex-direction: column;
}

.scan-bottom-bar {
  grid-area: scan-bottom-bar;
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 10 !important;
}
