.aiTrainingLayout {
  background-color: #ffffff;
  display: grid;
  width: 100%;
  height: 100vh;
  gap: 10px;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr 6fr;
  grid-template-areas:
    'aiTrainingInputBar'
    'aiTrainingTable';
}

.aiTrainingLayout .aiTrainingInputBar {
  grid-area: aiTrainingInputBar;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.aiTrainingLayout .aiTrainingMultipleActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.aiTrainingLayout .aiTrainingInputBar .aiTrainingDescription {
  color: var(--m-3black);
  font-family: 'Roboto', Helvetica;
  font-size: calc(12px + 0.5vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(16px + 1vh);
}

.aiTrainingLayout .aiTrainingInputBar .option {
  font-family: var(--m3-display-medium-font-family);
}

.aiTrainingLayout .aiTrainingTable {
  grid-area: aiTrainingTable;
  display: flex;
  justify-content: center;
}

.aiTrainingLayout .aiTrainingTable .aiTrainingProgressBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  /* Adjust the margin as needed */
  width: 100%;
}

.aiTrainingLayout .aiTrainingTable .aiTrainingProgressBarContainerButtons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.aiTrainingLayout .edit-and-delete-buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  height: 100%;
  align-items: center;
}
