/* CSS for QualitatioDateTimePicker */
.qualitatio-date-time-picker {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border-radius: 4px;
}

.qualitatio-date-time-picker .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-date-time-picker .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-date-time-picker .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1);
}

.qualitatio-date-time-picker .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1);
}

.qualitatio-date-time-picker .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70);
}

.qualitatio-date-time-picker-compact {
  /* Styles for the overall wrapper if needed */
  font-size: 12px;
}

.qualitatio-date-time-picker-compact .MuiOutlinedInput-root {
  padding: 0;
  height: 28px;
}

.qualitatio-date-time-picker-compact .MuiOutlinedInput-input {
  padding: 6px 14px;
  height: 28px;
  line-height: 28px;
}

.qualitatio-date-time-picker-compact .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
  font-size: small; /* You may adjust the size to fit the compact design */
}

/* Styles applied when the label is shrunken */
.qualitatio-date-time-picker-compact .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.65);
  font-size: medium; /* You may adjust the size to fit the compact design */
}

.qualitatio-date-time-picker-compact .MuiInputBase-root,
.qualitatio-date-time-picker-compact .MuiInputBase-input {
  padding: 0 6px;
  font-size: small;
}

.qualitatio-date-time-picker-compact .MuiFormControl-root {
  width: 100%;
}

.qualitatio-date-time-picker-compact .MuiInputLabel-outlined {
  transform: translate(14px, 6px) scale(1);
}
