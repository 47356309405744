.request-reset-password-page {
    width: 100%;
    height: 100%;
}

.request-reset-password-page .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1;
}

.request-reset-password-page .div-wrapper {
    align-self: stretch;
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 5vh;
}

.request-reset-password-page .text-wrapper-2 {
    color: var(--m-3black);
    font-family: var(--m3-display-medium-bold-font-family);
    font-size: calc(24px + 1vw);
    font-style: var(--m3-display-medium-bold-font-style);
    font-weight: var(--m3-display-medium-bold-font-weight);
    letter-spacing: var(--m3-display-medium-bold-letter-spacing);
    line-height: var(--m3-display-medium-bold-line-height);
    position: relative;
    text-align: center;
    justify-content: center;
    margin-bottom: 2vh;
}

.request-reset-password-page .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 60%;
    gap: 2vh;
    margin-bottom: 3vh;
    margin: 0 auto;
}

.request-reset-password-page .logo {
    width: 12%;
    position: relative;
    margin-bottom: 3vh;
}