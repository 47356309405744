body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Nebula-Regular';
  src: local('Nebula-Regular'), url('./assets/fonts/Nebula-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nebula-Hollow';
  src: local('Nebula-Hollow'), url('./assets/fonts/Nebula-Hollow.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: local('Roboto-BoldItalic'), url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto-LightItalic'), url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: local('Roboto-MediumItalic'), url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}