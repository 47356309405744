/* QualitatioInput.css */

.qualitatio-input {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border-radius: 4px;
}

.qualitatio-input .MuiOutlinedInput-root {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--m-3refprimaryprimary-70);
}

.qualitatio-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-outlined.Mui-focused {
  color: rgba(0, 106, 105, 1);
}

.qualitatio-input .MuiInputLabel-shrink {
  color: var(--m-3refprimaryprimary-70);
}

.compact-input .qualitatio-input {
  height: 28px;
  padding: 0;
  font-size: 12px;
}

.compact-input .MuiOutlinedInput-root {
  padding: 0;
  height: 28px;
}

.compact-input .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}

.compact-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.compact-input .qualitatio-input {
  height: 28px;
  font-size: 12px;
}

.compact-input .MuiOutlinedInput-root {
  padding: 0;
  height: 28px;
  line-height: 28px;
}

.compact-input .MuiOutlinedInput-input {
  padding: 6px 14px;
  height: 28px;
  line-height: 28px;
}

.compact-input .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
  font-size: small;
}

.compact-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.65);
  font-size: medium;
}

.compact-input .MuiInputBase-root,
.compact-input .MuiInputBase-input {
  padding: 0 6px !important;
  font-size: small;
}

.compact-input .MuiInputLabel-outlined {
  transform: translate(14px, 6px) scale(1);
}

.compact-input-multiline .MuiOutlinedInput-root {
  padding: 0; /* No padding on the root element */
  min-height: 28px; /* Minimum height, allowing expansion */
}

.compact-input-multiline .MuiOutlinedInput-input {
  padding: 6px 14px; /* Consistent padding for text content */
  line-height: 1; /* Line height to ensure space between lines is adequate */
  overflow: auto; /* Allows the input to expand as needed */
}

.compact-input-multiline .MuiInputLabel-outlined {
  transform: translate(14px, 6px) scale(1); /* Adjust for initial position */
  font-size: small; /* Keep font small for compact design */
}

.compact-input-multiline .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.6); /* Adjust for compact design when label shrinks */
  font-size: medium; /* Slightly larger font when label is active */
}

.compact-input-multiline .MuiInputBase-root,
.compact-input-multiline .MuiInputBase-input {
  padding: 4px 6px; /* Consistent horizontal and vertical padding */
  font-size: small; /* Small font size for compact design */
}

/* Ensure the TextField container allows for expansion */
.compact-input-multiline .MuiTextField-root {
  width: 100%; /* Full width to fit container */
  overflow: hidden; /* Hide overflow */
}

.xs-input .qualitatio-input {
  height: 36px; /* Adjust the height */
  padding: 0;
  font-size: 14px; /* Adjust the font size */
}

.xs-input .MuiOutlinedInput-root {
  padding: 0;
  height: 36px; /* Adjust the height */
  line-height: 32px; /* Adjust the line-height */
}

.xs-input .MuiOutlinedInput-input {
  padding: 4px 12px; /* Adjust the padding */
  height: 36px; /* Adjust the height */
  line-height: 32px; /* Adjust the line-height */
}

.xs-input .MuiInputLabel-outlined {
  transform: translate(16px, 3px) scale(0.9); /* Adjust the transform values */
  font-size: 14px; /* Adjust the font size */
}

.xs-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.85); /* Adjust the transform values */
  font-size: 14px; /* Adjust the   font size */
  background-color: white;
  padding-right: 5px;
}

.xs-input .MuiInputBase-root,
.xs-input .MuiInputBase-input {
  padding: 0 6px !important; /* Adjust the padding */
  font-size: 14px; /* Adjust the font size */
}
