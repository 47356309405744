/* I think these two are the only ones used in this file? */
.camera-wrapper {
  z-index: 100;
}

.defectInputCardsLayout {
  background-color: #ffffff;
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  grid-template-areas: 'swipeCards';
}

.document-error-layout {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.document-error-layout .document-div-2 {
  background-color: var(--m-3white);
  height: 844px;
  position: relative;
  width: 390px;
}

.document-error-layout .document-bottom-bar-white-instance {
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 10 !important;
  left: 0 !important;
}

.document-error-layout .document-error {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 93px;
  padding: 7px;
  position: absolute;
  top: calc(10px + 1vh);
}

.document-error-layout .document-card-light {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.35px solid;
  border-color: #c5c5c5e6;
  border-radius: 8.4px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  display: flex;
  gap: 7px;
  height: 349.41px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 194.01px;
}

.document-error-layout .document-card-frame {
  background-color: var(--white);
  border-radius: 8.4px;
  height: 347.41px;
  position: relative;
  width: 192.01px;
}

.document-error-layout .document-overlap-group-3 {
  border-radius: 8.4px;
  box-shadow: 0px 2.8px 2.8px #00000040;
  height: 347px;
  position: relative;
  width: 192px;
}

.document-error-layout .document-card-button-line {
  align-items: center;
  border-radius: 8.4px 8.4px 0px 0px;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 328px;
}

.document-error-layout .document-card-instance {
  background-color: var(--m-3refprimaryprimary-70) !important;
  border-color: #0e0d13 !important;
  border-radius: 0px 0px 8.4px 8.4px !important;
  border-top-style: solid !important;
  border-top-width: 0.7px !important;
  height: 19.2px !important;
  position: relative !important;
  width: 192.01px !important;
}

.document-error-layout .document-text-wrapper-3 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: calc(50% - 93px);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 185px;
}

.document-error-layout .document-frame-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 36px;
  left: 39px;
  position: absolute;
  top: 269px;
}

.document-error-layout .document-frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.document-error-layout .document-frame-4:hover {
  transform: scale(0.9);
}

.document-error-layout .document-group-wrapper {
  height: 36px;
  position: relative;
  width: 36px;
}

.document-error-layout .document-mask-group-wrapper {
  background-color: #b00020;
  border-radius: 18px;
  box-shadow:
    0px 3.98px 7.95px #1018280f,
    0px 3.98px 11.93px #1018281a;
  height: 36px;
  position: relative;
  width: 36px;
}

.document-error-layout .document-mask-group {
  height: 18px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 18px;
}

.document-error-layout .document-div-wrapper {
  height: 9px;
  position: relative;
  width: 36.6px;
}

.document-error-layout .document-text-wrapper-4 {
  color: var(--m-3syslighterror);
  font-family: 'Roboto', Helvetica;
  font-size: 7.2px;
  font-weight: 700;
  height: 10px;
  left: -5px;
  letter-spacing: 0.3px;
  line-height: 9.6px;
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
  width: 44px;
}

.document-error-layout .document-frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.2px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.document-error-layout .document-frame-5:hover {
  transform: scale(0.9);
}

.document-error-layout .document-i-o-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
}

.document-error-layout .document-img-wrapper {
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 18px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  height: 36px;
  position: relative;
  width: 36px;
}

.document-error-layout .document-frame-6 {
  height: 9.6px;
  position: relative;
  width: 41.4px;
}

.document-error-layout .document-text-wrapper-5 {
  color: var(--m-3refprimaryprimary-70);
  font-family: 'Roboto', Helvetica;
  font-size: 7.2px;
  font-weight: 700;
  height: 10px;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 9.6px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 41px;
}

.document-error-layout .document-overlap {
  height: 235px;
  left: 6px;
  position: absolute;
  top: 24px;
  width: 180px;
}

.document-error-layout .document-mask-group-2 {
  height: 13px;
  left: 165px;
  position: absolute;
  top: 51px;
  width: 14px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 2;
}

.document-error-layout .document-mask-group-3 {
  height: 13px;
  left: 1px;
  position: absolute;
  top: 51px;
  width: 14px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 2;
}

.document-error-layout .document-frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4.8px;
  left: 0;
  position: absolute;
  top: 0;
}

.document-error-layout .document-frame-wrapper {
  background-color: var(--m3syslightsecondary-fixed);
  border-radius: 4.8px;
  height: 120px;
  position: relative;
  width: 180.01px;
}

.document-error-layout .document-frame-8 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  left: 59px;
  position: relative;
  top: 41px;
}

.document-error-layout .document-frame-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.4px;
  justify-content: center; /* Vertically center content */
  position: relative;
  text-align: center; /* Center text horizontally */
  z-index: 2;
  width: fit-content;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.document-error-layout .document-frame-9:hover {
  transform: scale(0.9);
}

.document-error-layout .document-frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
}

.document-error-layout .document-text-wrapper-6 {
  color: var(--m-3refprimaryprimary-70);
  font-family: 'Roboto', Helvetica;
  font-size: 8.4px;
  font-weight: 700;
  letter-spacing: 0.06px;
  line-height: 12px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.document-error-layout .document-pruefort-wrapper {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
  width: 180.01px;
}

.document-error-layout .document-pruefort {
  align-items: center;
  background-color: #ffffff;
  border-radius: 2.4px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  display: flex;
  gap: 7px;
  height: 24px;
  padding: 1.4px 7px;
  position: relative;
  width: 180.01px;
}

.document-error-layout .document-icons-location-instance {
  background-image: url(https://c.animaapp.com/XGB824UX/img/iolocationoutline-1@2x.png) !important;
  height: 13.99px !important;
  left: unset !important;
  top: unset !important;
  width: 13.99px !important;
}

.document-error-layout .document-p {
  color: transparent;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 10.5px;
  margin-top: -0.6px;
  position: relative;
  width: 126px;
}

.document-error-layout .document-span {
  color: #0e0d13;
}

.document-error-layout .document-text-wrapper-7 {
  color: #ba1a1a;
  font-weight: 700;
}

.document-error-layout .document-icons-eye-instance {
  background-image: url(https://c.animaapp.com/XGB824UX/img/ioeyeoutline@2x.png) !important;
  height: 13.99px !important;
  left: unset !important;
  top: unset !important;
  width: 13.99px !important;
}

.document-error-layout .document-io-chatbubble-ellipses-outline {
  height: 13.99px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 13.99px !important;
}

.document-error-layout .document-text-wrapper-8 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 10.5px;
  margin-top: -0.6px;
  position: relative;
  width: 126px;
}

.document-error-layout .document-header {
  align-items: center;
  background-color: transparent;
  border-radius: 8.4px;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 0;
}

.document-error-layout .document-design-component-instance-node {
  background-color: var(--m-3refprimaryprimary-70) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 0.7px !important;
  border-color: #0e0d13 !important;
  border-radius: 8.4px 8.4px 0px 0px !important;
  height: 20.29px !important;
  position: relative !important;
  width: 192.41px !important;
}

.document-error-layout .document-text-wrapper-9 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: 112px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 3px;
  width: 73px;
}

.document-error-layout .document-text-wrapper-10 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: 7px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 59px;
}

.document-error-layout .document-text-wrapper-11 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 500;
  height: 14px;
  left: calc(50% - 19px);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 36px;
}

.document-error .captured-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 120px;
  border-radius: 8px;
  z-index: 1;
}

.document-error .document-delete-button {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.react-html5-camera-photo-fullscreen > video {
  height: calc(100vh - 56px);
}

#container-circles {
  bottom: 110px;
}

.defectFixedBadge span {
  top: 12px;
  right: max(calc(25% - 48px), 48px);
  position: absolute;
}
