.aiTraining .aiAllSteps {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'backContainer cardContent nextContainer';
  grid-gap: 2%;
}

.aiTraining .container-card {
  grid-area: cardContent;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 350px;
  margin: 15px auto;
  border-radius: 10px;
  box-shadow: var(--m3-elevation-light-1) !important;
  padding: 2%;
  min-height: 750px;
}

.aiTraining .steps-overview {
  width: 100%;
  text-align: center;
}

.aiTraining .add-quality-station-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.aiTraining .model-warranty-checkbox {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 1vh;
  margin-left: 1vw;
  width: 100%;
}

.aiTraining .step-3 {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'select-data-source'
    'csv-or-live'
    'date-picker';
  grid-gap: 2%;
}

.aiTraining .step-3 .csv-or-live {
  grid-area: csv-or-live;
  display: flex;
  flex-direction: row;
  gap: 3vh;
  margin-top: 2vh;
}

.aiTraining .step-3 .date-picker {
  grid-area: date-picker;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-top: 2vh;
  align-items: center;
}

.aiTraining .step-4-live,
.aiTraining .step-4-live-summary {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'select-prediction-fields'
    'select-data-source-as-features';
  grid-gap: 2%;
}

.aiTraining .step-3 .date-picker .select-date,
.aiTraining .step-4-live .select-prediction-fields .select-defect-classes-as-predictions,
.aiTraining .step-4-live .select-data-source-as-features .select-data-sources-as-features-text,
.aiTraining .step-5-live .list-of-all-fields-and-names .stream-name,
.aiTraining .step-5-live-summary .list-of-all-fields-and-names .stream-name {
  font-weight: var(--m3-headline-small-bold-font-weight);
}

.aiTraining .step-4-live .select-prediction-fields,
.aiTraining .step-4-live-summary .select-prediction-fields {
  grid-area: select-prediction-fields;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-top: 2vh;
}

.aiTraining .step-4-live .select-prediction-fields .number-of-predictions,
.aiTraining .step-4-live-summary .select-prediction-fields .number-of-predictions {
  font-size: var(--m3-label-large-bold-font-size);
  color: var(--m-3refprimaryprimary-70);
}

.aiAllSteps .prediction-streams-list {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.aiAllSteps .step-4-live .select-prediction-fields .prediction-streams-list-item,
.aiAllSteps .step-4-live .select-data-source-as-features .data-source-item,
.aiAllSteps .step-4-live-summary .select-prediction-fields .prediction-streams-list-item,
.aiAllSteps .step-4-live-summary .select-data-source-as-features .data-source-item {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  align-items: center;
  width: 100%;
}

.aiAllSteps .step-4-live .select-prediction-fields .prediction-streams-list-item,
.aiAllSteps .step-4-live-summary .select-prediction-fields .prediction-streams-list-item {
  justify-content: space-between;
}

.aiAllSteps .step-4-live .select-data-source-as-features .data-source-item,
.aiAllSteps .step-4-live-summary .select-data-source-as-features .data-source-item {
  justify-content: space-around;
}

.aiTraining .step-4-live .select-data-source-as-features .data-source-item-name,
.aiTraining .step-4-live-summary .select-data-source-as-features .data-source-item-name {
  display: flex;
  align-items: center;
}

.aiTraining .step-4-live .select-data-source-as-features .data-source-item-checkbox,
.aiTraining .step-4-live-summary .select-data-source-as-features .data-source-item-checkbox {
  margin-right: 10px;
}

.aiTraining .step-4-live .select-data-source-as-features,
.aiTraining .step-4-live-summary .select-data-source-as-features {
  grid-area: select-data-source-as-features;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-top: 2vh;
}

.aiTraining .step-5,
.aiTraining .step-7-summary {
  display: grid;
  grid-template-areas:
    'select-fields'
    'list-legend'
    'divider-container'
    'list-of-all-fields'
    'feature-and-prediction-fields';
  grid-gap: 2%;
}

.aiTraining .step-5-live {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    'select-fields'
    'list-legend'
    'divider-container'
    'list-of-all-fields-and-names';
  grid-gap: 2%;
}

.aiTraining .step-5-live-summary {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    'select-fields'
    'list-legend'
    'divider-container'
    'list-of-all-fields-and-names';
  grid-gap: 2%;
}

.aiTraining .step-5 .select-fields,
.aiTraining .step-7-summary .select-fields,
.aiTraining .step-5-live .select-fields,
.aiTraining .step-5-live-summary .select-fields {
  grid-area: select-fields;
  display: flex;
  gap: 2vw;
  justify-content: center;
}

.aiTraining .step-5 .list-legend,
.aiTraining .step-7-summary .list-legend,
.aiTraining .step-5-live .list-legend,
.aiTraining .step-5-live-summary .list-legend {
  grid-area: list-legend;
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin-top: 3vh;
}

.aiTraining .step-5 .divider-container,
.aiTraining .step-7-summary .divider-container,
.aiTraining .step-5-live .divider-container,
.aiTraining .step-5-live-summary .divider-container {
  grid-area: divider-container;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1px;
}

.aiTraining .step-5 .divider-container .MuiDivider-root,
.aiTraining .step-7-summary .divider-container .MuiDivider-root,
.aiTraining .step-5-live .divider-container .MuiDivider-root,
.aiTraining .step-5-live-summary .divider-container .MuiDivider-root {
  border-top: 1px solid var(--m-3refprimaryprimary-70);
  width: 100%;
}

.aiTraining .step-5 .list-of-all-fields,
.aiTraining .step-7-summary .list-of-all-fields {
  grid-area: list-of-all-fields;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 10px;
  max-width: 100%;
  height: fit-content;
}

.aiTraining .step-5 .list-of-all-fields > *,
.aiTraining .step-7-summary .list-of-all-fields > * {
  flex: 0 0 calc(16.66% - 10px);
}

.aiTraining .step-5-live .list-of-all-fields-and-names,
.aiTraining .step-5-live-summary .list-of-all-fields-and-names {
  grid-area: list-of-all-fields-and-names;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-bottom: 2vh;
}

.aiTraining .step-5-live .list-of-all-fields,
.aiTraining .step-5-live-summary .list-of-all-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
}

.aiTraining .step-5-live .list-of-all-fields > *,
.aiTraining .step-5-live-summary .list-of-all-fields > * {
  flex: 0 0 calc(16.66% - 10px);
}

.aiTraining .step-5 .feature-and-prediction-fields,
.aiTraining .step-7-summary .feature-and-prediction-fields {
  grid-area: feature-and-prediction-fields;
}

.aiTraining .step-6 .map-feature-fields,
.aiTraining .step-8-summary .map-feature-fields {
  width: 100%;
}

.aiTraining .step-6 .feature-field-mapping,
.aiTraining .step-8-summary .feature-field-mapping {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
}

.aiTraining .step-7-step-8-summary {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'step-7-summary'
    'step-8-summary';
  grid-gap: 2%;
}

.aiTraining .step-7-step-8-summary .step-7-summary {
  grid-area: step-7-summary;
}

.aiTraining .step-7-step-8-summary .step-8-summary {
  grid-area: step-8-summary;
  margin-bottom: 2vh;
}

/* Optional: Style the grid items */
.aiTraining .step-6 .grid-item,
.aiTraining .step-8-summary .grid-item,
.aiTraining .step-8 .grid-item,
.aiTraining .step-8-summary .grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.aiTraining .step-6 .grid-item .option,
.aiTraining .step-8-summary .grid-item .option,
.aiTraining .step-7 .algorithm-name .option,
.aiTraining .step-9-summary .algorithm-name .option,
.aiTraining .step-8 .caching-settings .option,
.aiTraining .step-10-summary .caching-settings .option {
  font-family: var(--m3-display-medium-font-family);
}

.aiTraining .step-7,
.aiTraining .step-9-summary {
  height: 100%;
  gap: 2vh;
}

.aiTraining .step-7 .built-in-algorithm,
.aiTraining .step-9-summary .built-in-algorithm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  margin-top: 4vh;
}

.aiTraining .step-7 .algorithm-name,
.aiTraining .step-9-summary .algorithm-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 4vh;
}

.aiTraining .step-8 .define-automatic-retraining,
.aiTraining .step-10-summary .define-automatic-retraining,
.aiTraining .step-8 .retraining-settings,
.aiTraining .step-10-summary .retraining-settings,
.aiTraining .step-8 .caching-settings,
.aiTraining .step-10-summary .caching-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  margin-top: 4vh;
}

.aiTraining .step-9 {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'check-model-settings'
    'model-settings';
  grid-gap: 2vh;
}

.aiTraining .step-9 .check-model-settings {
  grid-area: check-model-settings;
}

.aiTraining .step-9 .model-settings {
  grid-area: model-settings;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.aiTraining .step-9 .model-settings .model-name-input {
  width: 100%;
}

.aiTraining .summary-date-time-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vh;
  justify-content: left;
  width: 100%;
}

.aiTraining .step-12 .model-performance {
  display: flex;
  gap: 24px;
  position: relative;
  justify-content: center;
  margin-top: 3vh;
}

.aiTraining .step-12 .model-performance .model-performance-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.aiTraining .step-12 .model-performance .model-performance-ellipse {
  background-color: var(--m3syslightsecondary-fixed);
  border-radius: 50%;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aiTraining .step-12 .model-performance .model-performance-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.aiTraining .step-12 .model-performance .model-performance-number-wrapper {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.aiTraining .step-12 .model-performance .model-performance-text-wrapper {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  width: fit-content;
}

.aiTraining .step-12 .model-evaluation-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 7vh;
}

.aiTraining .select-module,
.aiTraining .select-data-source,
.aiTraining .name-the-model,
.aiTraining .select-data-set,
.aiTraining .select-fields,
.aiTraining .map-feature-fields,
.aiTraining .select-an-algorithm,
.aiTraining .define-retraining-and-caching,
.aiTraining .check-model-settings,
.aiTraining .evaluate-the-model-performance,
.aiTraining .select-defect-classes-as-predictions-text,
.aiTraining
  .step-4-live-summary
  .select-data-source-as-features
  .select-data-sources-as-features-text {
  margin-top: 2vh;
  text-align: center;
  font-size: calc(12px + 0.75vw);
  font-weight: 600;
}

.aiTraining .model-name-input,
.aiTraining .select-module-container,
.aiTraining .csv-or-live,
.aiTraining .data-set-input,
.aiTraining .feature-field-mapping,
.aiTraining .built-in-algorithm,
.aiTraining .define-all-settings {
  margin-top: 2vh;
}

.aiTraining .csv-or-live,
.aiTraining .select-module-container,
.aiTraining .data-set-input,
.aiTraining .list-of-all-fields,
.aiTraining .feature-field-mapping {
  display: flex;
  justify-content: center;
  gap: 5vw;
}

.aiTraining .select-module-container {
  margin: 2vh auto;
  width: 60%;
}

.aiTraining .quality-station,
.aiTraining .test-logic {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 2vh 1vw;
}

.aiTraining .quality-station-name-wrapper,
.aiTraining .test-logic-name-wrapper {
  width: 90%;
}

.aiTraining .quality-station-edit-wrapper,
.aiTraining .test-logic-info-wrapper,
.aiTraining .quality-station-delete-wrapper {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
}

.aiTraining .button {
  box-shadow:
    0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.aiTraining .button:hover {
  box-shadow:
    0px 1px 5px 2px rgba(0, 0, 0, 0.15),
    0px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.aiTraining .container-back {
  grid-area: backContainer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aiTraining .container-next {
  grid-area: nextContainer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aiTraining .highlighted-text {
  color: var(--m-3refprimaryprimary-70);
}

.aiTraining .error-message {
  color: red;
  font-size: calc(7.5px + 0.25vw);
  font-weight: var(--m3-headline-small-bold-font-weight);
  margin-top: 2vh;
  width: 100%;
}
