.qualitatio-chips-input-compact .MuiOutlinedInput-root {
  padding: 0;
  min-height: 28px; /* Ensure the minimum height of the input is compact */
}

.qualitatio-chips-input-compact .MuiOutlinedInput-input {
  padding: 6px 14px; /* Adjust padding to align text nicely inside the input */
  min-height: 28px;
  line-height: 28px; /* Set line height to match the height for vertical alignment */
}

.qualitatio-chips-input-compact .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1); /* Adjust the label to sit correctly when not focused */
  font-size: small; /* Smaller font size for a compact look */
}

.qualitatio-chips-input-compact .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.65); /* Adjust the label when the input is focused and shrinks */
  font-size: medium; /* Adjust font size to be legible but still compact */
}

.qualitatio-chips-input-compact .MuiInputBase-root,
.qualitatio-chips-input-compact .MuiInputBase-input {
  padding: 0 6px; /* Reduce padding on the sides for a more compact feel */
  font-size: small; /* Smaller font size consistent with other compact styles */
}

.qualitatio-chips-input-compact .MuiFormControl-root {
  width: 100%; /* Ensure the control takes full width to align with layout */
}

.qualitatio-chips-input-compact .MuiChip-root {
  height: 22px; /* Smaller chip height to match compact design */
  font-size: 12px; /* Smaller font size for chips */
  margin: 2px; /* Maintain small margins around chips for spacing */
}
