.error-circle {
    background-color: var(--m-3syslighterror) !important;
}

.fixed-circle {
    background-color: #3679f5 !important;
}

.success-circle {
    background-color: var(--m-3refprimaryprimary-70) !important;
}