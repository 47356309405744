.ai-wrapper {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 'ai cards';
  gap: 5%;
}

.ai-wrapper .ai {
  grid-area: ai;
  padding: 11.66px;
}

.ai-wrapper .cards {
  grid-area: cards;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.test-card-navigation .basis-nav-buttons {
  cursor: pointer;
}

.ai .ai-class-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background: var(--m-3-sys-light-surface-container-low, #f2f4f3);
  border: 0.58px solid;
  border-color: #c5c5c5e6;
  border-radius: 13.99px;
  box-shadow: 0px 6.63px 13.25px #1018280d;
  gap: 11.66px;
  height: 553.71px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 322.68px;
}

.ai .ai-header {
  align-items: center;
  background-color: transparent;
  border-radius: 13.99px;
  display: inline-flex;
  flex-direction: column;
  gap: 11.66px;
  left: 0;
  position: absolute;
  top: 0;
}

.ai .ai-body {
  display: flex;
  flex-direction: column;
  gap: 11.66px;
  margin-top: 33.82px;
  padding: 11.66px;
  width: 100%;
  overflow-y: auto;
}

.ai .card-2 {
  background-color: var(--m-3-sys-light-surface-container-highest, #e0e3e2);
  border-bottom-style: solid !important;
  border-bottom-width: 1.17px !important;
  border-color: #0e0d13 !important;
  border-radius: 13.99px 13.99px 0px 0px !important;
  height: 33.82px !important;
  position: relative !important;
  width: 320.68px !important;
}

.ai .text-wrapper-10 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 16.3px;
  font-weight: 700;
  height: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  top: 6px;
  width: 100%;
  min-width: fit-content;
}

.ai .ai-test {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 1vh 1vw 0vh 1vw;
}

.ai .ai-test-name-wrapper {
  width: 80%;
}

.ai .ai-test-delete-wrapper {
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
}

.ai .add-ai {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh 0 2vh 0;
}

.add-ai-class-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 10px;
  overflow-y: auto;
  gap: 8px;
}

.add-ai-class-wrapper .ai-test {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.add-ai-class-wrapper .ai-test:last-child {
  border-bottom: none;
}

.add-ai-class-wrapper .ai-test-name-wrapper {
  flex: 1;
  font-size: 16px;
  color: #333;
  padding: 8px;
}

.add-ai-class-wrapper .ai-test-checkbox-wrapper {
  padding: 8px;
}

.test-card-manipulation {
  gap: 8px;
}

.test-card-manipulation .test-card-manipulation-element {
  margin: 0 auto;
}
