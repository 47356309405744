@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  background-size: 100% 100%;
  animation: spin 2s linear infinite;
}

.spinner-with-BG-instance {
  height: 110px;
  width: 110px;
}