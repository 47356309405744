.testCardControlElement {
  margin: 0 10px;
  width: 36px !important;
  height: 36px !important;
}

.testCardNavigation {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin: 2% 0 0 0;
  margin-bottom: 20px;
}

.testCardManipulationElement {
  width: 48px !important;
  height: 48px !important;
}

.testCardManipulation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
}
