.test-feedback-layout-display {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'test-feedback-header'
    'test-feedback-icon'
    'test-feedback-text'
    'test-feedback-input'
    'test-feedback-buttons'
    'test-feedback-bottom-bar';
  gap: 2vh;
  margin-top: 3vh;
  max-height: calc(100vh - 60px - 70px);
}

.test-feedback-layout-hide {
  display: none;
}

.test-feedback-header {
  grid-area: test-feedback-header;
  display: flex;
  justify-content: center;
  font-family: var(--h4-ROB-font-family);
  font-size: calc(15px + 0.6vw);
  font-style: var(--h4-ROB-font-style);
  font-weight: var(--h4-ROB-font-weight);
  letter-spacing: var(--h4-ROB-letter-spacing);
}

.test-feedback-icon {
  grid-area: test-feedback-icon;
  display: flex;
  justify-content: center;
}

.test-feedback-image {
  width: calc(50px + 7vw);
  height: calc(45px + 6vh);
}

.test-feedback-text {
  grid-area: test-feedback-text;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: var(--m3-title-large-font-family);
  font-size: calc(15px + 0.6vw);
  font-style: var(--m3-title-large-font-style);
  font-weight: var(--m3-title-large-font-weight);
  letter-spacing: var(--m3-title-large-letter-spacing);
  line-height: var(--m3-title-large-line-height);
  gap: 0.5vh;
  margin-bottom: 4px; 
  margin-top: 4px;
  padding: 0; 
  line-height: 1; 
}

.test-feedback-input {
  grid-area: test-feedback-input;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.2vh;

}



/* Styles for the Textarea to Match QualitatioInput */
.test-feedback-textarea {
  transition: all 0.3s;
  border: 1px solid var(--m-3refprimaryprimary-70, #ccc);
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box; 
  font-size: 0.875rem; 
  line-height: 1.1;
  font-family: inherit;
  width: 70%; 
  min-height: 5vh; 
  resize: vertical; 
  margin-bottom: 16px; 
  white-space: pre-wrap;
  background-color: white; 
  color: #000; 
}

.test-feedback-textarea:focus {
  border-color: rgba(0, 106, 105, 1); 
  outline: none;
  
  
  box-shadow: 0 0 0 2px rgba(0, 106, 105, 0.2);
}

.test-feedback-textarea::placeholder {
  color: #888; 
  opacity: 1; 
}

.test-feedback-textarea:hover {
  border-color: var(--m-3refprimaryprimary-70, #aaa); 
}

.test-feedback-buttons {
  grid-area: test-feedback-buttons;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vh;
  margin-top: 0%;
  padding-bottom: 52px;
}

.test-feedback-bottom-bar {
  grid-area: test-feedback-bottom-bar;
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 10 !important;
}

.rating-container {
  text-align: center;
  display: flex;
  align-items: center;
  margin: 2px; 
  padding: 2px; 
  flex-direction: column; 
  justify-content: center;
}

.rating-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0rem;
}