/* I think this is the only one used in this file? */
.cardWrapperLayout .swipe {
  position: absolute;
  border-radius: 5px;
  max-height: 100%;
  height: 100%;
}

.swipeCardsLayoutNotVisible {
  background-color: #ffffff;
  display: none;
  width: 100%;
  height: 100vh;
  gap: 10px;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  grid-template-areas: 'swipeCards';
}

.swipeCards {
  grid-area: swipeCards;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  margin-top: calc(10px + 1vh);
  min-height: calc(300px + 12vh);
}

.test-card-AI {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cardWrapperLayout .div-2 {
  background-color: var(--m-3white);
  height: 844px;
  position: relative;
  width: 390px;
}

.cardWrapperLayout .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  position: relative;
}

.cardWrapperLayout .jumpBackCheckboxes .check-mark-in-a-wrapper {
  background-color: var(--m-3refprimaryprimary-70);
  border: 0.5px solid;
  border-color: var(--m-3black);
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
  cursor: pointer;
}

.cardWrapperLayout .jumpBackCheckboxes .img-3 {
  height: 10px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 10px;
}

.cardWrapperLayout .jumpBackCheckboxes .close-cross-in-wrapper {
  background-color: #b00020;
  border: 0.5px solid;
  border-color: #0e0d13;
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
  cursor: pointer;
}

.cardWrapperLayout .jumpBackCheckboxes .group-2 {
  background-color: #f2f4f7;
  border: 0.5px solid;
  border-color: var(--m-3black);
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
  cursor: default;
}

.cardWrapperLayout .card-light-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 92px;
  padding: 7px;
  position: absolute;
  top: 50px;
}

.cardWrapperLayout .card-light {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.35px solid;
  border-color: #c5c5c5e6;
  border-radius: 8.4px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  display: flex;
  gap: 7px;
  height: 376.4px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 194px;
}

.cardWrapperLayout .card-frame {
  background-color: var(--white);
  border-radius: 8.4px;
  height: 374.4px;
  position: relative;
  width: 192px;
}

.cardWrapperLayout .overlap-group-3 {
  border-radius: 8.4px;
  box-shadow: 0px 2.8px 2.8px #00000040;
  height: 374px;
  position: relative;
  width: 192px;
}

.cardWrapperLayout .card-button-line {
  align-items: center;
  border-radius: 8.4px 8.4px 0px 0px;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 355px;
}

.cardWrapperLayout .card-instance {
  background-color: var(--m-3refprimaryprimary-70) !important;
  border-color: #0e0d13 !important;
  border-radius: 0px 0px 8.4px 8.4px !important;
  border-top-style: solid !important;
  border-top-width: 0.7px !important;
  height: 19.2px !important;
  position: relative !important;
  width: 192px !important;
}

.cardWrapperLayout .text-wrapper-3 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: calc(50% - 93px);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 185px;
}

.cardWrapperLayout .overlap {
  height: 263px;
  left: 6px;
  position: absolute;
  top: 26px;
  width: 180px;
}

.cardWrapperLayout .frame-4 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 4.8px;
  left: 0;
  position: absolute;
  top: 0;
}

.cardWrapperLayout .highres {
  height: 119.4px;
  object-fit: cover;
  position: relative;
  width: 178.8px;
}

.cardWrapperLayout .frame-5 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
}

.cardWrapperLayout .div-3 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 2.4px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  display: flex;
  gap: 7px;
  height: 24px;
  padding: 1.4px 7px;
  position: relative;
  width: 180px;
}

.cardWrapperLayout .text-wrapper-4 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  height: auto;
  letter-spacing: 0;
  line-height: 1.2; /* Adjust the line-height as needed */
  margin-top: -0.94px;
  position: relative;
  width: 143.43px;
  display: flex; /* Add display: flex; to vertically center text */
  align-items: center; /* Add align-items: center; to vertically center text */
}

.cardWrapperLayout .icons-clipboard-instance {
  background-image: url(https://c.animaapp.com/QrfBNHCO/img/ioreaderoutline@2x.png) !important;
  height: 13.99px !important;
  left: unset !important;
  top: unset !important;
  width: 13.99px !important;
}

.cardWrapperLayout .text-wrapper-5 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: auto;
  letter-spacing: 0;
  line-height: 1.2;
  margin-top: -0.59px;
  position: relative;
  width: 143.43px;
  display: flex; /* Add display: flex; to vertically center text */
  align-items: center; /* Add align-items: center; to vertically center text */
}

.cardWrapperLayout .icons-location-instance {
  background-image: url(https://c.animaapp.com/QrfBNHCO/img/iolocationoutline@2x.png) !important;
  height: 13.99px !important;
  left: unset !important;
  top: unset !important;
  width: 13.99px !important;
}

.cardWrapperLayout .text-wrapper-6 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: auto;
  letter-spacing: 0;
  line-height: 1.2;
  margin-top: -1.29px;
  position: relative;
  width: 143.43px;
  display: flex; /* Add display: flex; to vertically center text */
  align-items: center; /* Add align-items: center; to vertically center text */
}

.cardWrapperLayout .icons-eye-instance {
  background-image: url(https://c.animaapp.com/QrfBNHCO/img/ioeyeoutline@2x.png) !important;
  height: 13.99px !important;
  left: unset !important;
  top: unset !important;
  width: 13.99px !important;
}

.cardWrapperLayout .io-chatbubble-ellipses-outline {
  height: 13.99px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 13.99px !important;
}

.cardWrapperLayout .p {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: auto; /* Remove fixed height */
  letter-spacing: 0;
  line-height: 1.2; /* Adjust the line-height as needed */
  margin-top: -0.94px;
  position: relative;
  width: 143.43px;
  display: flex; /* Add display: flex; to vertically center text */
  align-items: center; /* Add align-items: center; to vertically center text */
}

.cardWrapperLayout .badge-base-instance-nightsky {
  background-color: var(--customnightsky) !important;
  border-radius: 11.19px !important;
  display: flex !important;
  left: 62.11% !important;
  padding: 1.4px 7px !important;
  position: absolute !important;
  top: calc(50% - 127px) !important;
  width: 34.91% !important;
}

.cardWrapperLayout .badge-base-instance-blue {
  background-color: var(--m-3refprimaryprimary-70) !important;
  border-radius: 11.19px !important;
  display: flex !important;
  left: 62.11% !important;
  padding: 1.4px 7px !important;
  position: absolute !important;
  top: calc(50% - 127px) !important;
  width: 34.91% !important;
}

.cardWrapperLayout .design-component-instance-node {
  font-family: var(--h7-ROB-font-family) !important;
  font-style: var(--h7-ROB-font-style) !important;
  font-weight: var(--h7-ROB-font-weight) !important;
  letter-spacing: var(--h7-ROB-letter-spacing) !important;
  line-height: var(--h7-ROB-line-height) !important;
  margin-top: -0.7px !important;
}

.cardWrapperLayout .ai-outline-star-wrapper-dark {
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: var(--customnightsky);
  border-radius: 10.49px;
  height: 20px;
  left: 155px;
  position: absolute;
  top: 94px;
  width: 20px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.cardWrapperLayout .ai-outline-star-wrapper-dark:hover {
  transform: scale(0.9);
}

.cardWrapperLayout .ai-outline-star-wrapper-light {
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 10.49px;
  height: 20px;
  left: 155px;
  position: absolute;
  top: 94px;
  width: 20px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.cardWrapperLayout .ai-outline-star-wrapper-light:hover {
  transform: scale(0.9);
}

.cardWrapperLayout .ai-outline-star-instance {
  height: 10px !important;
  left: 3px !important;
  top: 3px !important;
  width: 10px !important;
}

.cardWrapperLayout .pic-points {
  align-items: flex-end;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 3.5px 3.5px;
  justify-content: center;
  left: 76px;
  position: absolute;
  top: 108px;
}

.cardWrapperLayout .ellipse {
  background-color: var(--customnightsky);
  border-radius: 3.5px;
  height: 7px;
  position: relative;
  width: 7px;
  cursor: pointer;
}

.cardWrapperLayout .ellipse-2 {
  background-color: #f5f7fa;
  border-radius: 3.5px;
  height: 7px;
  position: relative;
  width: 7px;
  cursor: pointer;
}

.cardWrapperLayout .mask-group {
  height: 14px;
  left: 165px;
  position: absolute;
  top: 55px;
  width: 14px;
  cursor: pointer;
}

.cardWrapperLayout .mask-group-2 {
  height: 14px;
  left: 1px;
  position: absolute;
  top: 55px;
  width: 14px;
  cursor: pointer;
}

.cardWrapperLayout .frame-6 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 4.2px;
  left: 33px;
  position: absolute;
  top: 295px;
}

.cardWrapperLayout .i-o-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.8px;
  position: relative;
}

.cardWrapperLayout .group-wrapper {
  height: 36px;
  position: relative;
  width: 36px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.cardWrapperLayout .group-wrapper:hover {
  transform: scale(0.9);
}

.cardWrapperLayout .mask-group-wrapper {
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 18px;
  box-shadow: 0px 3.98px 7.95px #1018280d;
  height: 36px;
  position: relative;
  width: 36px;
}

.cardWrapperLayout .mask-group-3 {
  height: 18px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 18px;
}

.cardWrapperLayout .div-wrapper {
  height: 9.6px;
  position: relative;
  width: 24px;
  display: flex;
  justify-content: center;
}

.cardWrapperLayout .text-wrapper-7 {
  color: var(--m-3refprimaryprimary-70);
  font-family: 'Roboto', Helvetica;
  font-size: 7.2px;
  font-weight: 600;
  height: 10px;
  letter-spacing: 0.3px;
  line-height: 9.6px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.cardWrapperLayout .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4.8px;
  left: 81px;
  position: absolute;
  top: 299px;
}

.cardWrapperLayout .frame-wrapper {
  box-shadow: 0px 3.98px 7.95px #1018280d;
  height: 28.8px;
  position: relative;
  width: 28.8px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.cardWrapperLayout .frame-wrapper:hover {
  transform: scale(0.9);
}

.cardWrapperLayout .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 6px;
  position: relative;
}

.cardWrapperLayout .ellipse-3 {
  background-color: var(--variable-collection-pure-white);
  border: 0.35px solid;
  border-color: var(--variable-collection-light-gray);
  border-radius: 14.4px;
  height: 28.8px;
  position: relative;
  width: 28.8px;
}

.cardWrapperLayout .group-3 {
  background-image: url(https://c.animaapp.com/QrfBNHCO/img/mask-group-3@2x.png);
  background-size: 100% 100%;
  height: 15px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 14px;
}

.cardWrapperLayout .frame-9 {
  height: 19.2px;
  position: relative;
  width: 29.4px;
  display: flex;
  justify-content: center;
}

.cardWrapperLayout .text-wrapper-8 {
  color: var(--m3syslightinverse-surface);
  font-family: 'Roboto', Helvetica;
  font-size: 7.2px;
  font-weight: 600;
  height: 20px;
  letter-spacing: 0.3px;
  line-height: 9.6px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.cardWrapperLayout .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4.8px;
  left: 123px;
  position: absolute;
  top: 295px;
}

.cardWrapperLayout .img-wrapper {
  background-color: #b00020;
  border-radius: 18px;
  box-shadow:
    0px 3.98px 7.95px #1018280f,
    0px 3.98px 11.93px #1018281a;
  height: 36px;
  position: relative;
  width: 36px;
}

.cardWrapperLayout .frame-11 {
  height: 9px;
  position: relative;
  width: 36.6px;
  display: flex;
  justify-content: center;
}

.cardWrapperLayout .text-wrapper-9 {
  color: var(--m-3syslighterror);
  font-family: 'Roboto', Helvetica;
  font-size: 7.2px;
  font-weight: 600;
  height: 10px;
  letter-spacing: 0.3px;
  line-height: 9.6px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.cardWrapperLayout .header {
  align-items: center;
  background-color: transparent;
  border-radius: 8.4px;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  left: 0;
  position: absolute;
  top: 0;
  border: none;
}

.cardWrapperLayout .card-2 {
  background-color: var(--m-3refprimaryprimary-70) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 0.7px !important;
  border-color: #0e0d13 !important;
  border-radius: 8.4px 8.4px 0px 0px !important;
  height: 20.29px !important;
  position: relative !important;
  width: 192.41px !important;
}

.cardWrapperLayout .text-wrapper-10 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: 113px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 3px;
  width: 75px;
  white-space: nowrap;
}

.cardWrapperLayout .text-wrapper-11 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  height: 14px;
  left: 7px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 59px;
}

.cardWrapperLayout .text-wrapper-12 {
  color: #0e0d13;
  font-family: 'Roboto', Helvetica;
  font-size: 9.8px;
  font-weight: 500;
  height: 14px;
  left: calc(50% - 19px);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 36px;
}

.cardWrapperLayout .text-wrapper-13 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cardWrapperLayout .tinderCardWrapper {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
