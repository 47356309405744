.avatar {
  height: 48px;
  position: relative;
  width: 48px;
  border-radius: 50%;
  border: 2px solid var(--m-3refprimaryprimary-70);
  display: inline-block;
  overflow: hidden;
}

.avatar-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100px;
  position: relative;
  z-index: 1;
}

.img-3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
