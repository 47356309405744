.modul-card {
  align-items: flex-start;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 350px;
  box-shadow: var(--m3-elevation-light-1);
  transition: box-shadow 0.2s ease-in-out;
  -webkit-transition: box-shadow 0.2s ease-in-out;
}

.modul-card:hover {
  box-shadow: var(--m3-elevation-light-4);
}

.modul-card .instance-node {
  color: var(--m-3white) !important;
}

.modul-card .class {
  background-color: var(--m-3white) !important;
  box-shadow: var(--m3-elevation-light-1) !important;
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.modul-card .class-2 {
  background-color: var(--m-3white) !important;
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
  max-width: 350px; 
}

.modul-card .modul-card-pr-fplanung {
  background-color: var(--m-3refprimaryprimary-70) !important;
}

.modul-card .modul-card-pr-instance {
  color: var(--m-3refprimaryprimary-70) !important;
  font-family: var(--m3-headline-small-font-family) !important;
  font-size: var(--m3-headline-small-bold-font-size) !important;
  font-style: var(--m3-headline-small-bold-font-style) !important;
  font-weight: var(--m3-headline-small-bold-font-weight) !important;
  letter-spacing: var(--m3-headline-small-bold-letter-spacing) !important;
  line-height: var(--m3-headline-small-bold-line-height) !important;
}

.modul-card .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.modul-card .rectangle-2 {
  background-color: var(--m-3refprimaryprimary-70);
  border-radius: 56.55px;
  height: 5.09px;
  position: relative;
  width: 96px;
}

.modul-card .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.modul-card .pr-fplanung-4 {
  color: var(--m-3refprimaryprimary-70);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 264px;
}

.modul-card .icons-iolayerssharp-2 {
  height: 30px;
  position: relative;
  width: 30px;
}

.modul-card .instance-node-2 {
  height: 30px !important;
  left: unset !important;
  top: unset !important;
  width: unset !important;
}

.modul-card .icons-ki-instance {
  height: 28px !important;
  left: 2px !important;
  width: 27px !important;
}

.modul-card .icons-ki-optimierung-instance {
  height: 27px !important;
  width: 28px !important;
}

.modul-card .supporting-text-2 {
  color: var(--m3syslighton-surface-variant);
  font-family: var(--m3-body-large-font-family);
  font-size: var(--m3-body-large-font-size);
  font-style: var(--m3-body-large-font-style);
  font-weight: var(--m3-body-large-font-weight);
  letter-spacing: var(--m3-body-large-letter-spacing);
  line-height: var(--m3-body-large-line-height);
  position: relative;
  width: 241px;
}

.modul-card .button-2 {
  align-items: flex-end !important;
  left: unset !important;
  top: unset !important;
}

.modul-card .icons-instance {
  height: 30px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 30px !important;
}

.modul-card.systemeinstellungen {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.KI-training {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.intelligentes-testen {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.monitoring {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.KI-optimierung {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.nutzerverwaltung {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.konsumentensysteme {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.business-insights {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.pr-fplanung {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.systemadministration {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.datenanbindung {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.KI-basiertes-pr-fen {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.k-nstliche-intelligenz {
  box-shadow: var(--m3-elevation-light-1);
}

.modul-card.reporting {
  box-shadow: var(--m3-elevation-light-1);
}
