.ai-edit-summary {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: 'model-settings';
  grid-gap: 2vh;
}

.ai-edit-summary .model-settings {
  grid-area: model-settings;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
}

.ai-edit-summary .summary-date-time-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vh;
  justify-content: left;
  width: 100%;
}

.ai-edit-summary .model-warranty-checkbox {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-left: 1vw;
  width: 100%;
}

.ai-edit-summary .select-data-source,
.ai-edit-summary .name-the-model,
.ai-edit-summary .select-data-set,
.ai-edit-summary .select-fields,
.ai-edit-summary .map-feature-fields,
.ai-edit-summary .select-an-algorithm,
.ai-edit-summary .define-retraining-and-caching,
.ai-edit-summary .check-model-settings,
.ai-edit-summary .evaluate-the-model-performance,
.ai-edit-summary .select-defect-classes-as-predictions-text,
.ai-edit-summary
  .step-3-live-summary
  .select-data-source-as-features
  .select-data-sources-as-features-text {
  margin-top: 2vh;
  text-align: center;
  font-size: calc(12px + 0.75vw);
  font-weight: 600;
}

.ai-edit-summary .step-3-live-summary {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'select-prediction-fields'
    'select-data-source-as-features';
  grid-gap: 2%;
}

.ai-edit-summary .step-3-live-summary .select-prediction-fields {
  grid-area: select-prediction-fields;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-top: 2vh;
}

.ai-edit-summary .step-3-live-summary .select-prediction-fields .number-of-predictions {
  font-size: var(--m3-label-large-bold-font-size);
  color: var(--m-3refprimaryprimary-70);
}

.ai-edit-summary .prediction-streams-list {
  max-height: 150px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.ai-edit-summary .step-3-live-summary .select-prediction-fields .prediction-streams-list-item,
.ai-edit-summary .step-3-live-summary .select-data-source-as-features .data-source-item {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  align-items: center;
  width: 100%;
}

.ai-edit-summary .step-3-live-summary .select-prediction-fields .prediction-streams-list-item {
  justify-content: space-between;
}

.ai-edit-summary .step-3-live-summary .select-data-source-as-features .data-source-item {
  justify-content: space-around;
}

.ai-edit-summary .step-3-live-summary .select-data-source-as-features .data-source-item-name {
  display: flex;
  align-items: center;
}

.ai-edit-summary .step-3-live-summary .select-data-source-as-features .data-source-item-checkbox {
  margin-right: 10px;
}

.ai-edit-summary .step-3-live-summary .select-data-source-as-features {
  grid-area: select-data-source-as-features;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: 2vh;
}

.ai-edit-summary .step-3-live .select-prediction-fields .select-defect-classes-as-predictions,
.ai-edit-summary .step-3-live .select-data-source-as-features .select-data-sources-as-features-text,
.ai-edit-summary .stream-name {
  font-weight: var(--m3-headline-small-bold-font-weight);
}

.ai-edit-summary .step-4-summary {
  display: grid;
  grid-template-areas:
    'select-fields'
    'list-legend'
    'divider-container'
    'list-of-all-fields'
    'feature-and-prediction-fields';
  grid-gap: 0.5vh;
}

.ai-edit-summary .step-4-live-summary {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    'select-fields'
    'list-legend'
    'divider-container'
    'list-of-all-fields-and-names';
  grid-gap: 2%;
}

.ai-edit-summary .step-4-summary .select-fields,
.ai-edit-summary .step-4-live-summary .select-fields {
  grid-area: select-fields;
  display: flex;
  gap: 2vw;
  justify-content: center;
}

.ai-edit-summary .step-4-summary .list-legend,
.ai-edit-summary .step-4-live-summary .list-legend {
  grid-area: list-legend;
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin-top: 3vh;
}

.ai-edit-summary .step-4-summary .divider-container,
.ai-edit-summary .step-4-live-summary .divider-container {
  grid-area: divider-container;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1px;
}

.ai-edit-summary .step-4-summary .divider-container .MuiDivider-root,
.ai-edit-summary .step-4-live-summary .divider-container .MuiDivider-root {
  border-top: 1px solid var(--m-3refprimaryprimary-70);
  width: 100%;
}

.ai-edit-summary .step-4-summary .list-of-all-fields {
  grid-area: list-of-all-fields;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 10px;
  max-width: 100%;
  height: fit-content;
}

.ai-edit-summary .step-4-summary .list-of-all-fields > * {
  flex: 0 0 calc(16.66% - 10px);
}

.ai-edit-summary .step-4-live-summary .list-of-all-fields-and-names {
  grid-area: list-of-all-fields-and-names;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.ai-edit-summary .step-4-live-summary .list-of-all-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
}

.ai-edit-summary .step-4-live-summary .list-of-all-fields > * {
  flex: 0 0 calc(16.66% - 10px);
}

.ai-edit-summary .step-4-summary .feature-and-prediction-fields {
  grid-area: feature-and-prediction-fields;
  margin-bottom: 4vh;
}

.ai-edit-summary .step-5-summary .map-feature-fields {
  margin-bottom: 2vh;
  width: 100%;
}

.ai-edit-summary .step-5-summary .feature-field-mapping {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
}

.ai-edit-summary .step-4-step-5-summary {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'step-4-summary'
    'step-5-summary';
  grid-gap: 2%;
}

.ai-edit-summary .step-4-step-5-summary .step-4-summary {
  grid-area: step-4-summary;
}

.ai-edit-summary .step-4-step-5-summary .step-5-summary {
  grid-area: step-5-summary;
  margin-bottom: 2vh;
}

/* Optional: Style the grid items */
.ai-edit-summary .step-5-summary .grid-item,
.ai-edit-summary .step-6-summary .grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ai-edit-summary .step-5-summary .grid-item .option,
.ai-edit-summary .step-6-summary .algorithm-name .option,
.ai-edit-summary .step-7-summary .caching-settings .option {
  font-family: var(--m3-display-medium-font-family);
}

.ai-edit-summary .step-6-summary {
  height: 100%;
  gap: 2vh;
}

.ai-edit-summary .step-6-summary .built-in-algorithm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  margin-top: 4vh;
}

.ai-edit-summary .step-6-summary .algorithm-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 4vh;
}

.ai-edit-summary .step-7-summary .define-automatic-retraining,
.ai-edit-summary .step-7-summary .retraining-settings,
.ai-edit-summary .step-7-summary .caching-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  margin-top: 4vh;
}

.ai-edit-summary .model-active {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 2vw;
  width: 100%;
}

.ai-edit-summary .model-active .model-active-text {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.ai-edit-summary .model-performance {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 3vh;
}

.ai-edit-summary .model-performance .model-performance-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ai-edit-summary .model-performance .model-performance-ellipse {
  background-color: var(--m3syslightsecondary-fixed);
  border-radius: 50%;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-edit-summary .model-performance .model-performance-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ai-edit-summary .model-performance .model-performance-number-wrapper {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-headline-small-bold-font-family);
  font-size: var(--m3-headline-small-bold-font-size);
  font-style: var(--m3-headline-small-bold-font-style);
  font-weight: var(--m3-headline-small-bold-font-weight);
  letter-spacing: var(--m3-headline-small-bold-letter-spacing);
  line-height: var(--m3-headline-small-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.ai-edit-summary .model-performance .model-performance-text-wrapper {
  color: var(--m-3refprimaryprimary-40);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  width: fit-content;
}

.ai-edit-summary .model-performance .model-evaluation-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 7vh;
}

.ai-edit-summary .summary-date-time-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vh;
  justify-content: left;
  width: 100%;
}

.ai-edit-summary .error-message {
  color: red;
  font-size: calc(7.5px + 0.25vw);
  font-weight: var(--m3-headline-small-bold-font-weight);
  display: flex;
  justify-content: left;
  width: 100%;
}
