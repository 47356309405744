.addButton {
    width: 250px;
}

.buttonGrid {
    margin-top: 10px;
}

.dialogContent {
    padding-top: 10px;
}

.autoComplete {
    margin-top: 10px;
}