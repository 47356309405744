.select-layout {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'select-welcome-bar'
        'select-station'
        'select-bottom-bar';
    gap: 6vh;
    max-height: calc(100vh - 60px - 70px);
    margin-top: 2vh;
    width: 100%;
}

.select-welcome-bar {
    grid-area: select-welcome-bar;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--h4-ROB-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--h4-ROB-font-style);
    font-weight: var(--h4-ROB-font-weight);
    letter-spacing: var(--h4-ROB-letter-spacing);
}

.select-welcome-bar .select-welcome-content {
    display: flex;
    justify-content: space-around;
    width: 80%;
    align-items: center;
}

.select-station {
    grid-area: select-station;
    display: flex;
    justify-content: center;
}

.select-station-content {
    display: flex;
    justify-content: center;
    width: 80%;
    gap: 3vh;
    flex-direction: column;
}

.select-station-text {
    display: flex;
    justify-content: center;
    font-family: var(--h4-ROB-font-family);
    font-size: calc(15px + 0.6vw);
    font-style: var(--h4-ROB-font-style);
    font-weight: var(--h4-ROB-font-weight);
    letter-spacing: var(--h4-ROB-letter-spacing);
}

.no-quality-stations-available {
    display: flex;
    justify-content: center;
    width: 100%;
}

.select-bottom-bar {
    grid-area: select-bottom-bar;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
}