.dropzone-active,
.dropzone-inactive {
    padding: 16px;
    border-radius: 4px;
}

.dropzone-active {
    border: 2px solid var(--m-3refprimaryprimary-70);
}

.dropzone-inactive {
    border: 2px dashed gray;
}

.dropzone-active:hover,
.dropzone-inactive:hover {
    cursor: pointer;
}

.dropzone-active .csv-icon {
    color: var(--m-3refprimaryprimary-70);
}

.dropzone-inactive .csv-icon {
    color: gray;
}

.dropzone-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid var(--m-3refprimaryprimary-70);
    margin-bottom: 8px;
    max-width: 10vw;
}