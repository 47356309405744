.navigationbaropen {
  align-items: center;
  background-color: var(--customnightsky);
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 16px 8px;
  position: relative;
  width: 296px;
  grid-area: nav;
  z-index: 1;
}

.navigationbaropen .link {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  position: relative;
  width: 272px;
}

.navigationbaropen .link a {
  height: 24px;
}

.navigationbaropen .img {
  height: 24px;
  position: relative;
  width: 24px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.navigationbaropen .img svg {
  fill: white;
  stroke: white;
}

.navigationbaropen .label {
  color: var(--m-3white);
  flex: 1;
  font-family: var(--m3-label-large-bold-font-family);
  font-size: var(--m3-label-large-bold-font-size);
  font-style: var(--m3-label-large-bold-font-style);
  font-weight: var(--m3-label-large-bold-font-weight);
  letter-spacing: var(--m3-label-large-bold-letter-spacing);
  line-height: var(--m3-label-large-bold-line-height);
  position: relative;
  margin: auto;
}

.navigationbaropen .label:hover {
  cursor: pointer;
}

.navigationbaropen .icons-einklappen-instance {
  left: unset !important;
  top: unset !important;
}

.navigationbaropen .icons-einklappen-instance:hover {
  cursor: pointer;
}

.navigationbaropen .dropdown {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 272px;
}

.navigationbaropen .div {
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
  width: 100%;
}

.navigationbaropen .div:hover {
  cursor: pointer;
  background-color: var(--customnightskypale);
}

.navigationbaropen .links-container {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 208px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.navigationbaropen .link-2 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
  width: 196px;
}

.navigationbaropen .link-2:hover {
  cursor: pointer;
  background-color: var(--customnightskypale);
}

.navigationbaropen .text-wrapper {
  color: var(--m-3white);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  width: 148px;
}

.navigationbaropen .label-2 {
  color: var(--m-3white);
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
  width: 124px;
}

.navigationbaropen .design-component-instance-node {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.navigationbaropen .label-3 {
  color: var(--m-3white);
  flex: 1;
  font-family: var(--m3-label-large-font-family);
  font-size: var(--m3-label-large-font-size);
  font-style: var(--m3-label-large-font-style);
  font-weight: var(--m3-label-large-font-weight);
  letter-spacing: var(--m3-label-large-letter-spacing);
  line-height: var(--m3-label-large-line-height);
  position: relative;
}

.navigationbaropen .line {
  background-color: var(--m-3refprimaryprimary-90);
  height: 100%;
  left: -13px;
  position: absolute;
  top: 0;
  width: 2px;
}

.navigationbaropen .design-component-instance-node-2 {
  left: unset !important;
  top: unset !important;
}

.navigationbaropen .link-3 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
  width: 272px;
}

.navigationbaropen .link-3:hover {
  cursor: pointer;
  background-color: var(--customnightskypale);
}
