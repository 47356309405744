.footer {
  align-items: center;
  background-color: var(--customnightsky);
  border-color: var(--m-3white);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  height: 56px;
  grid-area: footer;
  justify-content: space-between;
  padding: 0px 36px 0px 24px;
  position: relative;
  width: 100%;
}

.footer .frame-5 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.footer .text-wrapper-3 {
  color: var(--m-3white);
  font-family: var(--m3-label-large-bold-font-family);
  font-size: var(--m3-label-large-bold-font-size);
  font-style: var(--m3-label-large-bold-font-style);
  font-weight: var(--m3-label-large-bold-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--m3-label-large-bold-letter-spacing);
  line-height: var(--m3-label-large-bold-line-height);
  position: relative;
  top: 17px;
  width: 166px;
}

.footer .FAQ-LEGAL-CONTACT {
  color: var(--m-3white);
  font-family: var(--m3-label-large-bold-font-family);
  font-size: var(--m3-label-large-bold-font-size);
  font-style: var(--m3-label-large-bold-font-style);
  font-weight: var(--m3-label-large-bold-font-weight);
  height: 20px;
  letter-spacing: var(--m3-label-large-bold-letter-spacing);
  line-height: var(--m3-label-large-bold-line-height);
  position: relative;
  text-align: right;
  top: 17px;
  white-space: nowrap;
}

.footer .FAQ-LEGAL-CONTACT a {
  color: var(--m-3white);
}
